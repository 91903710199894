import { Controller } from "stimulus"
import { PDFDocument, StandardFonts } from "pdf-lib"
import { LSID_SECRET_KEY, SSID_SECRET_KEY } from "../config/storage_identifiers"
import {createLinkOverlay, drawLogo, drawTextVerticallyCentered, openPdfInNewTab} from "../src/pdf_lib"

async function createPdf(template, data) {
  const pdf = await PDFDocument.load(template)
  const font = await pdf.embedFont(StandardFonts.Courier)
  const pages = pdf.getPages()
  const [firstPage] = pages

  await addDataToPdf(pdf, firstPage, data, font)

  return pdf.save()
}

async function addDataToPdf(pdf, page, data, font) {
  await drawLogo(pdf, page, data.logoUrl, data.logoType)

  const urlRect = drawTextVerticallyCentered(page, data.url, {
    x: 90,
    y: 384,
    size: 12,
    maxWidth: 420,
    wordBreaks: ["/", "?", "&"],
    lineHeight: 12,
    font,
  })

  createLinkOverlay(page, data.url, urlRect)

  page.drawText(data.email, {
    x: 90,
    y: 327,
    size: 12,
    font,
  })

  page.drawText(data.secretKey, {
    x: 90,
    y: 270,
    size: 12,
    font,
  })
}

export default class extends Controller {
  static values = {
    email: String,
    templateUrl: String,
    filename: String,
    url: String,
    logoUrl: String,
    logoType: String,
  }

  async triggerPdfCreation() {
    const secretKey = sessionStorage.getItem(SSID_SECRET_KEY) || localStorage.getItem(LSID_SECRET_KEY)
    if (!secretKey) { return }

    const data = {
      secretKey: secretKey,
      email: this.emailValue,
      url: this.urlValue,
      logoUrl: this.logoUrlValue,
      logoType: this.logoTypeValue,
    }

    try {
      const template = await fetch(this.templateUrlValue).then(res => res.arrayBuffer())
      const pdfBytes = await createPdf(template, data)
      openPdfInNewTab(pdfBytes)
    } catch (e) {
      // TODO: Error handling.
      console.error("PDF creation failed", e)
    }
  }
}
