import { Controller } from "stimulus"
import { isBlank } from "../src/helper"

const TRIGGER_KIND_CATEGORY = "category"
const TRIGGER_KIND_FORM_ELEMENT = "form_element"
const TRIGGER_KIND_ORGANIZATION = "organization"

export default class extends Controller {
  static targets = ["formElementSelect", "formOptionSelect", "formOptionSelectOption"]

  // lifecycle
  connect() { this.filterFormOptions(true) }

  // event handlers
  onFormElementSelectChange() {
    this.formOptionSelectTarget.selectedIndex = 0
    this.filterFormOptions()
  }

  // internal
  filterFormOptions(initialRun) {
    const [triggerKind, formElementId] = this.formElementSelectTarget.value?.split("/", 2)

    this.formOptionSelectOptionTargets.forEach((option) => {
      let belongsToSelectedTrigger
      const isEmptyOption = isBlank(option.value)

      if (triggerKind == TRIGGER_KIND_CATEGORY) {
        belongsToSelectedTrigger = option.dataset.trigger == triggerKind
      } else if (triggerKind == TRIGGER_KIND_ORGANIZATION) {
        belongsToSelectedTrigger = option.dataset.trigger == triggerKind
      } else if (triggerKind == TRIGGER_KIND_FORM_ELEMENT) {
        belongsToSelectedTrigger = option.dataset.formElementId == formElementId
      }

      const shouldbeVisible = isEmptyOption || belongsToSelectedTrigger
      option.classList.toggle("d-none", !shouldbeVisible)
    })

    // Unselect whatever the User had selected previously.
    // We don't do this on the initial run, as the initial selection on the edit view is the current instance's value and we don't want to blank this out.
    if (!initialRun) { this.formOptionSelectTarget.selectedIndex = -1 }
  }
}
