import * as base64 from "./base64"

function encodeText(text) {
  const encoder = new TextEncoder()
  return encoder.encode(text)
}

export async function generateKey() {
  return await window.crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-512",
    },
    true,
    ["encrypt", "decrypt"]
  )
}

export async function exportKey(key) {
  return await window.crypto.subtle.exportKey("jwk", key)
}

export async function parsePublicKey(jwkExport) {
  return await window.crypto.subtle.importKey(
    "jwk",
    jwkExport,
    {
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-512"
    },
    true,
    ["encrypt"]
  )
}

export async function parsePrivateKey(jwkExport) {
  return await window.crypto.subtle.importKey(
    "jwk",
    jwkExport,
    {
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-512"
    },
    true,
    ["decrypt"]
  )
}

export async function encryptText(textData, publicKey) {
  const encodedData = encodeText(textData)
  const ciphertext = await window.crypto.subtle.encrypt(
    { name: "RSA-OAEP" },
    publicKey,
    encodedData
  )
  return base64.bytesToBase64(ciphertext)
}

export async function decryptText(ciphertextBase64, privateKey) {
  const ciphertext = base64.base64ToBytes(ciphertextBase64)
  const decrypted = await window.crypto.subtle.decrypt(
    { name: "RSA-OAEP" },
    privateKey,
    ciphertext
  )

  const decoder = new TextDecoder()
  return decoder.decode(decrypted)
}
