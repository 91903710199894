import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.listeners = {
      toggleVisibility: this.toggleVisibility.bind(this)
    }
  }

  connect() { document.addEventListener("click", this.listeners.toggleVisibility) }
  disconnect() { document.removeEventListener("click", this.listeners.toggleVisibility) }

  toggleVisibility(event) {
    const element = event.target
    const targetSelectors = this.getTargetSelectors(element)
    if (!targetSelectors) { return }

    targetSelectors.forEach(targetSelector => {
      const targetNodes = [...document.querySelectorAll(`[data-toggle-visibility-target="${targetSelector.trim()}"]`)]
      targetNodes.forEach((targetNode) => targetNode.classList.toggle("d-none"))
    })
  }

  getTargetSelectors(element) {
    const elementWithSelectors = element.closest("[data-toggle-visibility]")
    if (!elementWithSelectors) { return }

    return elementWithSelectors.dataset.toggleVisibility.split(",")
  }
}
