import { DirectUpload } from "@rails/activestorage"
import { filetypename } from "magic-bytes.js"

import { MAXIMUM_FILE_SIZE, MAXIMUM_NUMBER_OF_FILES } from "../config/file_upload"

const validFile = async (file, allowedExtensions) => {
  const { name: fileName, size: fileSize } = file

  // Simple checks first: file size and extension. DOCX/XLSX are ZIP archives, so the magic bytes check alone won't filter out archives.
  if (fileSize > MAXIMUM_FILE_SIZE) { return false }

  const fileExtension = fileName.split(".").pop().toLowerCase()
  if (!allowedExtensions.includes(fileExtension)) { return false }

  // Slightly more involved check: magic byte check to detect the actual file type, regardless of file name.
  const data = await file.arrayBuffer()
  const validFileTypeFound = filetypename(new Uint8Array(data))
    .some(possibleFileType => allowedExtensions.includes(possibleFileType) )

  if (!validFileTypeFound) { return false }

  return true
}

export const hasInvalidFiles = async (files, allowedExtensions) => {
  if (!files) { return false }

  let invalidFileFound = false

  for (const file of files) {
    const valid = await validFile(file, allowedExtensions)
    if (valid) { continue }

    invalidFileFound = true
    break
  }

  return invalidFileFound
}

export const hasTooManyFiles = (files) => {
  if (!files) { return false }

  return files.length > MAXIMUM_NUMBER_OF_FILES
}

const filterFiles = async (files, allowedExtensions) => {
  if (!files) { return [] }
  const validFiles = []

  for (const file of files) {
    const valid = await validFile(file, allowedExtensions)
    if (!valid) { continue }

    validFiles.push(file)
  }

  return validFiles.slice(0, MAXIMUM_NUMBER_OF_FILES)
}

export class FileUploadEncryption {
  constructor(options) {
    this.cryptoHandler = options.cryptoHandler
    this.uploadUrl = options.uploadUrl
    this.form = options.form
    this.encryptedFileInputName = options.encryptedFileInputName
    this.files = []
    this.hiddenFields = []
  }

  async prepareFiles(files, allowedExtensions) {
    this.files = await filterFiles(files, allowedExtensions)
  }

  async prepareEncryptedFileUpload() {
    this.encryptedFiles = await Promise.all(this.files.map(async (file) => {
      return await this.cryptoHandler.encryptFile(file)
    }))

    await this.uploadEncryptedFiles()
  }

  async uploadEncryptedFiles() {
    const signedBlobIds = await Promise.all(this.encryptedFiles.map(async (file) => {
      return await this.createEncryptedDirectUpload(file)
    }))

    signedBlobIds.forEach(signedId => {
      this.createHiddenFieldForEncryptedUpload(signedId)
    })
  }

  async createEncryptedDirectUpload(file) {
    const upload = new DirectUpload(file, this.uploadUrl)

    return new Promise((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) {
          reject(file)
        } else {
          resolve(blob.signed_id)
        }
      })
    })
  }

  // Add an appropriately-named hidden input to the form with a value of blob.signed_id so that the blob ids will be transmitted in the normal upload flow.
  createHiddenFieldForEncryptedUpload(signedId) {
    const hiddenField = document.createElement("input")

    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", signedId)
    hiddenField.name = this.encryptedFileInputName

    this.form.appendChild(hiddenField)
    this.hiddenFields.push(hiddenField)
  }

  clearHiddenFields() {
    this.hiddenFields.forEach(field => field.remove())
  }
}
