import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.setTimeout(() => {
      this.element.scrollIntoView({
        behaviour: "smooth",
      })
    }, 0)
  }
}
