import { Controller } from "stimulus"
import { SESSION_KEEP_ALIVE_DELTA_IN_MS } from "../config/session"

export default class extends Controller {
  connect() {
    // Navigating replaces <body> and thus restarts the process but renews the session itself, so we can wait a bit before starting our keep alive system.
    this.lastKeepAliveSentAt = SESSION_KEEP_ALIVE_DELTA_IN_MS
  }

  async handleEvent(event) {
    const elapsedTime = event.timeStamp - this.lastKeepAliveSentAt
    if (elapsedTime < SESSION_KEEP_ALIVE_DELTA_IN_MS) { return }

    this.lastKeepAliveSentAt += elapsedTime
    await this.sendKeepAlive()
  }

  async sendKeepAlive() {
    fetch("/keep_alive", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")?.content
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
  }
}
