import { BODY_CONTENT_OVERLAY_CSS_CLASS } from "../config/session"
import "bootstrap"
import TomSelect from "tom-select";

export function installTurboHooks() {
  fixBootstrapTooltipsAfterTurboNavigation()
  removeOverlayClassFromBodyBeforeRendering()
}

function fixBootstrapTooltipsAfterTurboNavigation() {
  document.addEventListener("turbo:load", () => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll(`[data-bs-toggle="tooltip"]`))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    if (document.querySelectorAll(".tom-select").length) {
      new TomSelect(".tom-select", {
        plugins:["remove_button"],
        sortField: {
          field: "text",
          direction: "asc"
        }
      })
    }
  })
}

function removeOverlayClassFromBodyBeforeRendering() {
  document.addEventListener("turbo:before-render", (event) => {
    event.detail.newBody.classList.remove(BODY_CONTENT_OVERLAY_CSS_CLASS)
  })
}
