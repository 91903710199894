const FALLBACK_LOCALE = "de"
// Legacy links don't carry the locale in the URL so the resulting locale is "notes". We need to catch this.
const LOCALE_NOTES = "notes"

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isBlank(str) {
  if (!str) { return true }
  return str.trim().length === 0;
}

export function getLocaleFromUrl() {
  const locale = window.location.pathname.split("/").find(part => !!part ) || FALLBACK_LOCALE
  if (locale === LOCALE_NOTES) { return FALLBACK_LOCALE }

  return locale
}
