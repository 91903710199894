import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["typeSelect", "repeatableTargetContainer"]
    static values = {supportedTypes: Array}

    // callbacks

    connect() {
        this.typeSelectTarget.addEventListener('change', this.onTypeChange.bind(this))
        this.toggleCheckboxBasedOnType(this.typeSelectTarget.value)
    }

    disconnect() {
        this.typeSelectTarget.removeEventListener('change', this.onTypeChange)
    }

    // private

    onTypeChange(e) {
        this.toggleCheckboxBasedOnType(e.target.value)
    }

    toggleCheckboxBasedOnType(type) {
        const typeInt = Number.parseInt(type)
        const show = this.supportedTypesValue.includes(typeInt)
        this.repeatableTargetContainerTarget.classList.toggle("d-none", !show)
    }
}
