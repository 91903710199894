import * as symmetric_crypto from "./symmetric_crypto"
import * as base64 from "./base64"
import { LSID_ACCOUNT_DATA } from "../config/storage_identifiers"

export const SECRET_KEY_ERRORS = {
  encryptionFailed: "encryptionFailed",
  invalidPassword: "invalidPassword"
}

export async function storeEncryptedAccountData({secretKey, email, password}) {
  if (!secretKey || !email || !password) { return }

  try {
    const encryptionKey = await symmetric_crypto.deriveEncryptionKeyFromPassword(password)
    const iv = symmetric_crypto.generateIV()
    const encryptedSecretKey = await symmetric_crypto.encryptText(secretKey, encryptionKey, iv)
    const combinedEncryptedSecretKey = `${encryptedSecretKey}.${base64.bytesToBase64(iv)}`

    localStorage.setItem(LSID_ACCOUNT_DATA, JSON.stringify({
      encryptedSecretKey: combinedEncryptedSecretKey,
      email: email
    }))
  } catch(e) { return SECRET_KEY_ERRORS.encryptionFailed }
}

export function parseStoredAccountData() {
  const storedAccountData = localStorage.getItem(LSID_ACCOUNT_DATA)
  if (!storedAccountData) { return emptyStoredAccountData() }

  const parsedAccountData = JSON.parse(storedAccountData)
  if (!storedAccountDataIsComplete(parsedAccountData)) { return emptyStoredAccountData() }

  return {
    encryptedSecretKey: parsedAccountData.encryptedSecretKey,
    email: parsedAccountData.email
  }
}

export function emptyStoredAccountData() {
  return {
    encryptedSecretKey: null,
    email: null
  }
}

export function isSecretKeyHandlingError(result) {
  return !!SECRET_KEY_ERRORS[result]
}

function storedAccountDataIsComplete(parsedAccountData) {
  try {
    return !!(parsedAccountData.encryptedSecretKey && parsedAccountData.email)
  } catch(e) { return false }
}
