import { Controller } from "stimulus"
import { LSID_ACCOUNT_DATA, SSID_PRIVATE_KEY, SSID_SECRET_KEY } from "../config/storage_identifiers"

export default class extends Controller {
  static values = {
    logoutButtonSelector: String
  }

  // Lifecycle

  initialize() {
    this.listeners = {
      handleStorageChange: this.handleStorageChange.bind(this)
    }
  }

  connect() {
    this.currentAccountData = localStorage.getItem(LSID_ACCOUNT_DATA)
    window.addEventListener("storage", this.listeners.handleStorageChange)
  }

  disconnect() {
    window.removeEventListener("storage", this.listeners.handleStorageChange)
  }

  // Event handlers

  handleStorageChange(event) {
    switch (event.key) {
      case null:
      case LSID_ACCOUNT_DATA:
        this.logoutIfAccountDataHasChanged(event.oldValue, event.newValue)
        break;
    }
  }

  logoutIfAccountDataHasChanged(oldValue, newValue) {
    if (oldValue === newValue) { return }

    this.clearStorage()
    this.logout()

  }

  // Helpers

  clearStorage() {
    sessionStorage.removeItem(SSID_PRIVATE_KEY)
    sessionStorage.removeItem(SSID_SECRET_KEY)
  }

  logout() {
    if (this.logoutButton) { this.logoutButton.click() }
  }

  // Getters

  get logoutButton() { return document.querySelector(this.logoutButtonSelectorValue) }
}
