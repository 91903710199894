import {Controller} from "stimulus"

const ARIA_EXPANSION_STATES = {
  expanded: "true",
  collapsed: "false"
}

export default class extends Controller {
  static values = {
    closeLabel: String,
    openLabel: String
  }

  updateAriaLabel() {
    if (this.element.ariaExpanded == ARIA_EXPANSION_STATES.expanded) {
      this.element.ariaLabel = this.closeLabelValue
    } else {
      this.element.ariaLabel = this.openLabelValue
    }
  }
}
