import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "output"]

  generate() {
    const input = this.inputTarget.value
    const output = input.toLowerCase().replaceAll(" ", "-").replaceAll(/[^a-z0-9\-]/g, "")
    this.outputTarget.value = output
  }
}
