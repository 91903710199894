import { sleep } from "./helper"

const MAX_NUMBER_OF_ROUNDS = 50
const ROUND_DELAY = 100

const getCryptoHandler = (application) => {
  const decryptionController = application.getControllerForElementAndIdentifier(
    document.querySelector(`[data-controller*="public-note-decryption"]`),
    "public-note-decryption"
  ) || application.getControllerForElementAndIdentifier(
    document.querySelector(`[data-controller*="note-decryption"]`),
    "note-decryption"
  )

  if (!decryptionController) { return }

  return decryptionController.cryptoHandler
}

export const waitForCryptohandler = async (application) => {
  return new Promise(async (resolve) => {
    let cryptoHandler = null

    for(let round = 0; round <= MAX_NUMBER_OF_ROUNDS; round++) {
      cryptoHandler = getCryptoHandler(application)
      if (cryptoHandler) { break }

      await sleep(ROUND_DELAY)
    }

    if (cryptoHandler) {
      resolve(cryptoHandler)
    } else {
      resolve(false)
    }
  })
}
