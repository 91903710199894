import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["package", "parentAccount"]
    static values = {
        packageSubAccount: String
    }

    // callbacks

    connect() {
        this.updatePackages()
    }

    updatePackages() {
        const hasParentAccount = !!this.getSelected(this.parentAccountTarget)?.value;

        [...this.packageTarget.options].forEach(option => {
            const isSubAccountOption = option.value == this.packageSubAccountValue
            const enabled = hasParentAccount == isSubAccountOption
            option.disabled = !enabled
        })

        this.selectFirstEnabled(this.packageTarget)
    }

    // private

    getSelected(target) {
        return target.options[target.selectedIndex]
    }

    selectFirstEnabled(target) {
        if (this.getSelected(target).disabled) {
            const firstEnabledOptionIndex = [...target.options].findIndex(o => !o.disabled)
            target.selectedIndex = firstEnabledOptionIndex
        }
    }
}