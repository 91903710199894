import { Controller } from "stimulus"
import { navigateTo } from "../src/navigation"
import { SSID_PRIVATE_KEY } from "../config/storage_identifiers"
import { BODY_CONTENT_OVERLAY_CSS_CLASS } from "../config/session"

export default class extends Controller {
  static values = { redirectUrl: String }

  connect() {
    const privateKeyJwkJsonDump = sessionStorage.getItem(SSID_PRIVATE_KEY)
    if (!privateKeyJwkJsonDump) {
      navigateTo(this.redirectUrlValue)
      return
    }

    document.body.classList.remove(BODY_CONTENT_OVERLAY_CSS_CLASS)
  }
}
