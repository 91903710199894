import { Controller } from "stimulus"
import * as dom from "../src/dom_helper"
import { LSID_SECRET_KEY } from "../config/storage_identifiers"

export default class extends Controller {
  static targets = [
    "errorContainer",
    "successContainer",
    "successAlert"
  ]

  // Lifecycle

  connect() {
    const legacySecretKey = localStorage.getItem(LSID_SECRET_KEY)

    if (legacySecretKey) {
      this.successAlertTarget.innerText = legacySecretKey
      dom.showElement(this.successContainerTarget)
    } else {
      dom.showElement(this.errorContainerTarget)
    }
  }
}
