import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "trigger"]

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    this.contentTarget.classList.toggle("d-none")
    this.triggerTarget.classList.toggle("rotate--180")
  }
}
