import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["passwordField"]

  connect() {
    this.maskFunction = () => { this.maskPassword() }
  }

  revealPassword() {
    this.passwordFieldTarget.type = "text"
    document.addEventListener("mouseup", this.maskFunction)
    document.addEventListener("touchend", this.maskFunction)
  }

  maskPassword() {
    this.passwordFieldTarget.type = "password"
    document.removeEventListener("mouseup", this.maskFunction)
    document.removeEventListener("touchend", this.maskFunction)
  }
}
