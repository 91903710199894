import { Controller } from "stimulus"

export default class extends Controller {
  click(e) {
    if (e.target.closest("[data-skip-table-row-click]")) { return }

    Turbo.visit(e.target.closest("tr").dataset.url)
  }

  open(e) {
    if (e.target.closest("[data-skip-table-row-click]")) { return }

    window.open(e.target.closest("tr").dataset.url)
  }
}
