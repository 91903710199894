import { Controller } from "stimulus"
import FileSaver from "file-saver"
import ExcelJS from "exceljs/dist/exceljs.bare.min.js"
import { FIELD_TYPES } from "../config/forms"
import { SSID_PRIVATE_KEY } from "../config/storage_identifiers"
import { isEmptyRepeatedBlock } from "../src/note_decrypted_content_ui"
import { formatDate } from "../src/date_helper"
import * as asymmetricCrypto from "../src/asymmetric_crypto"
import * as base64 from "../src/base64"
import * as dom from "../src/dom_helper"
import * as symmetricCrypto from "../src/symmetric_crypto"

const DOCUMENTATION_VALUE_TYPE_NESTED = "nested"
const DOCUMENTATION_VALUE_TYPE_REGULAR = "regular"
const NOTE_STATUS_DELETED = "deleted"


const buildHeaderData = (noteData) => {
  // Headers
  const headers = [
    "Basisdaten",
    "Nummer",
    "Erstellt am",
    "Archiviert am",
    "Gelöscht am",
    "Sprache",
    "Thema",
    "Bereich",
    "",
    "Hinweisdaten"
  ]

  const startIndexes = { noteSubmission: headers.length }

  // Add the FormElement labes from the Note submission processs.
  const noteSubmissionFormLabels = noteData.content_form_elements.map((formElement) => formElement.label)
  headers.push(...noteSubmissionFormLabels)

  headers.push(...["", "Workflow"])
  startIndexes.documentation = headers.length
  // Add the labels of all possible documentation fields.
  const documentationFormLabels = noteData.documentation.map((formElement) => formElement.label)
  headers.push(...documentationFormLabels)

  headers.push(...["", "Ergebnis"])
  startIndexes.valuation = headers.length
  // Add the labels of all possible valuation fields.
  const valuationFormLabels = noteData.valuations.map((formElement) => formElement.label)
  headers.push(...valuationFormLabels)

  // The summary is not included in the NoteValuation since it is just the raw field Note#summary, so we have to manually add a header.
  startIndexes.summary = headers.length
  headers.push("Zusammenfassung")

  startIndexes.end = headers.length
  return { headers, startIndexes }
}

export default class extends Controller {
  static targets = [
    "currentCounter",
    "encryptedEncryptionKeysInput",
    "progressBar",
    "sourceData"
  ]

  static values = {
    filename: String,
    totalCount: Number
  }

  // Entrypoints

  async startExport() {
    this.currentCount = 0
    this.multilineCells = {}
    dom.lockElement(this.element)

    const privateKey = await this.loadPrivateKey()
    if (!privateKey) { return }

    const importStatus = await this.loadData()
    if (!importStatus) { return }

    const { headers, startIndexes } = buildHeaderData(this.noteData)
    this.startIndexes = startIndexes
    let contentData = await this.buildContentData({ privateKey, startIndexes })
    // Remove all empty entries, i.e. those that couldn't be decrypted.
    contentData = contentData.filter(entry => entry)

    dom.unlockElement(this.element)

    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet("Excel-Export")
    const headerRow = sheet.addRow(headers)
    headerRow.font = { bold: true }
    sheet.addRows(contentData)

    // Make sure all cells are top-aligned.
    sheet.columns.forEach((column) => {
      if (!column.alignment) { column.alignment = {} }
      column.alignment.vertical = "top"
    })

    // Mark all cells that contain a line break as multiline.
    for (const [excelRowIndex, columnIndexes] of Object.entries(this.multilineCells)) {
      const row = sheet.getRow(excelRowIndex)

      columnIndexes.forEach((excelColumnIndex) => {
        row.getCell(excelColumnIndex).alignment = { vertical: "top", wrapText: true }
      })
    }

    const buffer = await workbook.xlsx.writeBuffer()
    FileSaver.saveAs(new Blob([buffer]), this.filenameValue)
  }

  // Actions

  async buildContentData({ privateKey, startIndexes }) {
    return Promise.all(this.noteData.notes.map(async (note, rowIndex) => {
      const data = new Array(startIndexes.end)
      const encryptedDataFormatVersion = note.encrypted_data_format_version
      let decryptedData

      data.splice(0, 0, ...[
        "",
        note.number,
        note.created_at ? new Date(note.created_at) : null,
        note.closed_at ? new Date(note.closed_at) : null,
        (note.status == NOTE_STATUS_DELETED && note.delete_at) ? new Date(note.delete_at) : null,
        note.locale,
        note.category,
        note.organization,
        "",
        ""
      ])

      // If a Note is deleted, the actual content is no longer available, so there's nothing to decrypt.
      if (note.status !== NOTE_STATUS_DELETED) {
        try { decryptedData = await this.decryptSingleNoteData({ privateKey, note }) } catch (e) { return }

        // Add decrypted Note submission content.
        const noteSubmissionData = []
        for (const [formElementIdOrLabel, contentItem] of Object.entries(decryptedData)) {
          this.addNoteSubmissionDataEntry(noteSubmissionData, formElementIdOrLabel, contentItem, encryptedDataFormatVersion, rowIndex)
        }

        // Insert data at the correct place in the combined data array.
        data.splice(startIndexes.noteSubmission, 0, ...noteSubmissionData)

        // Add Note documentation data.
        const documentationData = []
        for (const [formElementId, valueObj] of Object.entries(note.documentation)) {
          this.addDocumentationEntry(documentationData, formElementId, valueObj, rowIndex)
        }

        data.splice(startIndexes.documentation, 0, ...documentationData)
      }

      // Add Note valuation data.
      const valuationData = []
      for (let [formElementId, value] of Object.entries(note.valuations)) {
        const formElement = this.noteData.valuations.find((fe) => { return fe.id === formElementId })
        if (!formElement) { continue }

        if (Array.isArray(value)) { value = value.join(", ") }

        const index = this.noteData.valuations.indexOf(formElement)
        valuationData[index] = value
      }

      data.splice(startIndexes.valuation, 0, ...valuationData)

      // The valuation summary is just the field Note#summary. It's included in the generic note data, so we just place it and the end and are done.
      data[startIndexes.summary] = note.summary

      this.increaseProgress()
      return data
    }))
  }

  addNoteSubmissionDataEntry(data, formElementIdOrLabel, item, encryptedDataFormatVersion, rowIndex, nestedIndex) {
    if (encryptedDataFormatVersion == 1) {
      this.setNoteSubmissionDataFormElementValue(data, formElementIdOrLabel, item, "label", rowIndex)
    } else if (item.fieldType !== FIELD_TYPES.repeatable) {
      this.setNoteSubmissionDataFormElementValue(data, formElementIdOrLabel, item.data, "id", rowIndex, nestedIndex)
    } else {
      item.data.forEach((nestedData, index) => {
        if (isEmptyRepeatedBlock(nestedData)) { return }

        for (const [nestedFormElementIdOrLabel, nestedItem] of Object.entries(nestedData)) {
          this.addNoteSubmissionDataEntry(
            data,
            nestedFormElementIdOrLabel,
            nestedItem,
            encryptedDataFormatVersion,
            rowIndex,
            index + 1
          )
        }
      })
    }
  }

  addDocumentationEntry(documentationData, formElementId, valueObj, rowIndex) {
    if (valueObj.type == DOCUMENTATION_VALUE_TYPE_REGULAR) {
      const formElement = this.noteData.documentation.find((fe) => { return fe.id === formElementId })
      if (!formElement) { return }

      const index = this.noteData.documentation.indexOf(formElement)

      if (!Array.isArray(valueObj.data)) { valueObj.data = [valueObj.data] }

      let value = valueObj.data.map((valueOrId) => {
        const formOption = this.noteData.form_options.find((fo) => { return fo.id === valueOrId} )
        return formOption?.label || valueOrId
      }).join(", ")

      if (valueObj.nestedIndex) { value = `${valueObj.nestedIndex}: ${value}` }
      if (documentationData[index]) { value = [documentationData[index] || "", value].join("\n") }

      documentationData[index] = value
      this.potentiallyMarkCellAsMultiline(value, rowIndex, this.startIndexes.documentation + index)

    } else if (valueObj.type == DOCUMENTATION_VALUE_TYPE_NESTED) {
      valueObj.data.forEach((nestedDocumentation, nestedIndex) => {
        for (const [nestedFormElementId, value] of Object.entries(nestedDocumentation)) {
          this.addDocumentationEntry(
            documentationData,
            nestedFormElementId,
            {
              type: DOCUMENTATION_VALUE_TYPE_REGULAR,
              nestedIndex: nestedIndex + 1,
              data: value
            },
            rowIndex
          )
        }
      })
    }
  }

  buildNoteConfirmationContent(confirmationData) {
    const content = []

    if (confirmationData.requested_at && confirmationData.requested_by) {
      content.push(`${confirmationData.requested_by} hat die Bestätigung am ${formatDate(confirmationData.requested_at)} angefragt.`)
    } else if (confirmationData.requested_at) {
      content.push(`(Gelöschter Benuzter) hat die Bestätigung am ${formatDate(confirmationData.requested_at)} angefragt.`)
    }

    if (confirmationData.confirmed_at && confirmationData.confirmed_by) {
      content.push(`${confirmationData.confirmed_by} hat die Bestätigung am ${formatDate(confirmationData.confirmed_at)} erteilt.`)
    } else if (confirmationData.confirmed_at) {
      content.push(`(Gelöschter Benuzter) hat die Bestätigung am ${formatDate(confirmationData.confirmed_at)} erteilt.`)
    }

    if (confirmationData.rejected_at && confirmationData.rejected_by) {
      content.push(`${confirmationData.rejected_by} hat die Bestätigung am ${formatDate(confirmationData.rejected_at)} abgelehnt.`)
    } else if (confirmationData.rejected_at) {
      content.push(`(Gelöschter Benuzter) hat die Bestätigung am ${formatDate(confirmationData.rejected_at)} abgelehnt.`)
    }

    return content.join("\n")
  }

  setNoteSubmissionDataFormElementValue(contentData, formElementIdOrLabel, contentItem, fieldToMatch, rowIndex, nestedIndex) {
    const formElement = this.noteData.content_form_elements.find(fe => fe[fieldToMatch] === formElementIdOrLabel)
    if (!formElement) { return }

    const index = this.noteData.content_form_elements.indexOf(formElement)

    // In v2, the submitted data is always an array, even if it only contains a single entry, but we're using this method for both v1 and v2, so we check first.
    let value = Array.isArray(contentItem) ? contentItem.join(", ") : contentItem

    if (nestedIndex) { value = `${nestedIndex}: ${value}` }
    if (contentData[index]) { value = [contentData[index] || "", value].join("\n") }

    contentData[index] = value
    this.potentiallyMarkCellAsMultiline(value, rowIndex, this.startIndexes.noteSubmission + index)
  }

  // Multiline column definitions helper

  potentiallyMarkCellAsMultiline(value, rowIndex, columnIndex) {
    if (!value.includes("\n")) { return }

    const excelColumnIndex = columnIndex + 1
    // Excel starts at 1 and the first row is a header row.
    const excelRowIndex = rowIndex + 2

    if (!this.multilineCells[excelRowIndex]) { this.multilineCells[excelRowIndex] = [] }
    this.multilineCells[excelRowIndex].push(excelColumnIndex)
  }

  // Loading and decryption helpers

  async decryptSingleNoteData({ privateKey, note }) {
    const iv = base64.base64ToBytes(note.encryption_iv)
    const encryptedJson = note.encrypted_json_data
    const encryptedEncryptionKeyData = this.sourceEncryptionKeys.find((keyData) => {
      return keyData.noteId === note.id
    })

    if (!encryptedEncryptionKeyData) { return }

    const encryptionKeyJwk = JSON.parse(await asymmetricCrypto.decryptText(
      encryptedEncryptionKeyData.encryptedEncryptionKey,
      privateKey
    ))

    const cryptoHandler = new symmetricCrypto.SymmetricCryptoHandler({
      encryptionKey: await symmetricCrypto.importEncryptionKey(encryptionKeyJwk),
      iv: iv
    })

    return JSON.parse(await cryptoHandler.decryptText(encryptedJson))
  }

  async loadData() {
    if (!this.hasSourceDataTarget) { return }

    try {
      const sourceData = JSON.parse(this.sourceDataTarget.innerText)
      const publicKeyJwk = JSON.parse(sourceData.public_key)
      this.publicKey = await asymmetricCrypto.parsePublicKey(publicKeyJwk)
      this.noteData = sourceData.note_data
      this.sourceEncryptionKeys = sourceData.source_encryption_keys
      return true
    } catch(e) {
      return false
    }
  }

  async loadPrivateKey() {
    const privateKeyJwkJsonDump = sessionStorage.getItem(SSID_PRIVATE_KEY)
    if (!privateKeyJwkJsonDump) { return }

    return await asymmetricCrypto.parsePrivateKey(JSON.parse(privateKeyJwkJsonDump))
  }

  // Progress UI

  increaseProgress() {
    this.currentCount += 1
    const progressPercentage = Math.round((this.currentCount / this.totalCountValue) * 100)

    this.currentCounterTarget.innerText = this.currentCount
    this.progressBarTarget.style.width = `${progressPercentage}%`
  }
}
