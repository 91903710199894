import { Controller } from "stimulus"

export default class extends Controller {
  navigationEnabled = false

  // Lifecycle
  connect() {
    this.disableTurbo()
    window.addEventListener("beforeunload", e => this.preventNavigation(e))
  }

  // Events
  enableNavigation() {
    this.navigationEnabled = true
  }

  // Internal
  disableTurbo() {
    document.querySelectorAll("a").forEach(el => {
      el.dataset.turbo = "false"
    })
  }

  preventNavigation(e) {
    if (this.navigationEnabled) { return }

    e.preventDefault()
    e.returnValue = "showDefaultBrowserDialog"
  }
}
