import {Controller} from "stimulus"

export default class extends Controller {

    static targets = ["form", "entry"]

    openForm() {
        event.preventDefault()
    }

    submit(event) {
        event.preventDefault()

        let $form = $(this.formTarget)
        let url = $form.attr('action')
        let data = $form.serialize()

        $.ajax({
            url: url,
            type: 'post',
            data: data,
            success: (data) => this._success(data)
        })
    }

    _success(data) {
        this.entryTarget.outerHTML = data
    }

}