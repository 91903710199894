import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["category"]

  submit(event) {
    event.preventDefault()

    this.categoryTargets.forEach((el, i) => {
      if (el.checked) {
        Turbo.visit(this.data.get("url").replace(":id", el.value))
      }
    })
  }
}
