import { Controller } from "stimulus"
import { lockElement, unlockElement } from "../src/dom_helper"
import { waitForCryptohandler } from "../src/crypto_handler_importer"


export default class extends Controller {
  static targets = [
    "form",
    "hiddenInput",
    "input",
  ]
  static values = {
    allowedFileExtensions: Array
  }

  // Lifecycle

  async connect() {
    lockElement(this.element)

    this.cryptoHandler = await waitForCryptohandler(this.application)
    if (this.cryptoHandler) { unlockElement(this.element) }

    this.canBeSubmitted = false
  }

  // Entrypoints

  async submit(event) {
    try {
      if (this.canBeSubmitted) { return true }

      event.preventDefault()
      if (!this.cryptoHandler) { return }

      const encryptedText = await this.cryptoHandler.encryptText(this.inputTarget.value)
      this.hiddenInputTarget.value = encryptedText
      this.canBeSubmitted = true

      this.formTarget.requestSubmit()
    } catch (e) {
      unlockElement(this.element)
      this.canBeSubmitted = false
    }
  }
}
