import { Controller } from "stimulus"
import { navigateTo } from "../src/navigation"
import { SESSION_TIMEOUT_IN_MS } from "../config/session"
import { SSID_PRIVATE_KEY, SSID_SECRET_KEY, SSID_SESSION_TIMEOUT_TIMESTAMP } from "../config/storage_identifiers"

function hasExpired(timestamp) {
  const timeDifference = Date.now() - timestamp
  return (timeDifference > SESSION_TIMEOUT_IN_MS)
}

export default class extends Controller {
  static values = {
    logoutButtonSelector: String,
    redirectUrl: String
  }

  // Lifecycle

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }
    // If the User has just navigated here and their timestamp is still valid, refresh it.
    if (this.checkTimeStamp()) { this.refreshTimeStamp() }
  }

  disconnect() {
    if (this.idleTimeout) { clearTimeout(this.idleTimeout) }
  }

  // Actions

  checkTimeStamp() {
    // Read the timeout value from SessionStorage. If it has been reached or there is none, clear the saved password.
    const timeoutTimestamp = sessionStorage.getItem(SSID_SESSION_TIMEOUT_TIMESTAMP)
    const parsedTimeoutTimestamp = typeof timeoutTimestamp === "string" ? parseInt(timeoutTimestamp) : null

    // If there is no timestamp, we just opened this tab. Multiple tabs are not supported BUT opening a new tab should not invalidate the existing session in the previous tab, so we musn't click the logout button. Instead, we just redirect to the login page (potentially: a warning page informing the User of this condition.)
    if (!parsedTimeoutTimestamp) {
      this.clearStorage()
      navigateTo(this.redirectUrlValue)
      return false
    }

    if (hasExpired(parsedTimeoutTimestamp)) {
      this.clearStorage()
      this.logout()
      return false
    }

    // Reset our idle timeout to last another 30 seconds.
    this.setupIdleTimeout()
    return true
  }

  // Update the timestamp to be valid another 30 minutes.
  refreshTimeStamp() {
    sessionStorage.setItem(SSID_SESSION_TIMEOUT_TIMESTAMP, Date.now().toString())
  }

  setupIdleTimeout() {
    if (this.idleTimeout) { clearTimeout(this.idleTimeout) }
    this.idleTimeout = setTimeout(() => { this.checkTimeStamp() }, 30_000)
  }

  // Helpers

  clearStorage() {
    sessionStorage.removeItem(SSID_PRIVATE_KEY)
    sessionStorage.removeItem(SSID_SECRET_KEY)
  }

  logout() {
    if (this.logoutButton) { this.logoutButton.click() }
  }

  // Getters

  get logoutButton() { return document.querySelector(this.logoutButtonSelectorValue) }
}
