import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.isChrome = navigator?.userAgentData?.brands?.some(data => data.brand == "Chromium")
  }

  // For some reason, this doesn't trigger when binding it to the form submit action. Binding it to the click action on the submit button seems to work for all cases, though.
  fixChromiumError(event) {
    if (!this.isChrome) { return }
    if (this.element.checkValidity()) { return }

    document.documentElement.classList.add("remove-smooth-scroll")
    this.element.reportValidity()
    document.documentElement.classList.remove("remove-smooth-scroll")
  }
}
