import { Controller } from "stimulus"

export default class extends Controller {
    static values = { url: String }

    // events
    async refresh() {
        const res = await fetch(this.urlValue, { headers: { 'Accept': 'application/json' }})
        const note = await res.json()
        this.element.innerHTML = note.status
    }
}
