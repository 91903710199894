import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  // events
  changeAccounts(e) {
    const params = new URL(document.location).searchParams
    const ids = this.checkboxTargets.filter(c => c.checked).map(c => c.value)
    params.delete("account_id[]")
    ids.forEach(id => params.append("account_id[]", id))
    this.reload(params)
  }

  changeTimeFrame(e) {
    const time_frame = e.target.dataset.time_frame
    const params = new URL(document.location).searchParams
    params.set("time_frame", time_frame)
    this.reload(params)
    e.preventDefault()
  }

  // internal
  reload(searchParams) {
    Turbo.visit(`${window.location.pathname}?${searchParams.toString()}`, { action: "replace" })
  }
}
