export function formatDate(dateOrString) {
  try {
    let date

    if (dateOrString instanceof Date) {
      date = dateOrString
    } else if(dateOrString instanceof String || typeof dateOrString === "string") {
      date = new Date(dateOrString)
    }

    const day = rightPad(date.getDate(), 2)
    // getMont() is 0-indexed, because why not.
    const month = rightPad(date.getMonth() + 1, 2)
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
  } catch(e) {
    console.error(e)
  }
}

function rightPad(value, numberOfDigits, paddingCharacter = "0") {
  let stringValue = value.toString()
  const stringLength = stringValue.length

  for (let i = stringLength; i < numberOfDigits; i++) {
    stringValue = `${paddingCharacter}${stringValue}`
  }

  return stringValue
}
