import { Controller } from "stimulus"
import { WidgetInstance } from "friendly-challenge"

export default class extends Controller {
  static targets = [
    "captchaContainer",
    "submitButton"
  ]

  static values = {
    sitekey: String
  }

  // lifecycle

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.widget = new WidgetInstance(this.captchaContainerTarget, {
      doneCallback: () => { this.enableSubmitButton() },
      errorCallback: () => { this.disableSubmitButton() },
      sitekey: this.sitekeyValue,
      language: "de",
      skipStyleInjection: true,
    })
  }

  disconnect() {
    this.widget?.destroy()
  }

  // callbacks

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
  }
}
