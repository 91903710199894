import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["step", "navEntry"]

  show(e) {
    e.preventDefault()

    const stepName = e.target.closest(".wizard-step").dataset.step
    this.activateStep(stepName)
    this.activateNavEntry(stepName)
  }

  showNext(e) {
    e.preventDefault()

    const currentStep = this.stepTargets.find(el => el.dataset.currentStep === 'true')
    const nextStepName = currentStep.nextElementSibling.dataset.step
    this.activateStep(nextStepName)
    this.activateNavEntry(nextStepName)
  }

  // private

  activateStep(activeStep) {
    this.stepTargets.forEach(el => {
      el.classList.toggle("d-none", el.dataset.step !== activeStep)
      el.dataset.currentStep = el.dataset.step === activeStep
    })
  }

  activateNavEntry(activeStep) {
    let previous = true
    let next = false

    this.navEntryTargets.forEach(el => {
      const step = el.dataset.step
      previous = previous && step !== activeStep
      el.dataset.currentStep = step === activeStep
      el.dataset.previousStep = previous
      el.dataset.nextStep = next
      next = next || step === activeStep
    })
  }
}
