import { Controller } from "stimulus"
import { lockElement, unlockElement } from "../src/dom_helper"
import { FileUploadEncryption, hasInvalidFiles, hasTooManyFiles } from "../src/file_upload_encryption"
import { waitForCryptohandler } from "../src/crypto_handler_importer"
import { isBlank } from "../src/helper"
import * as dom from "../src/dom_helper"

const DOM_IDS = {
  noteStatusBadge: "note-status-badge"
}

export default class extends Controller {
  static targets = [
    "content",
    "encryptedFileInput",
    "errorMessage",
    // This is the source file input which we are going to use to read the files and encrypt them.
    "fileInput",
    "form",
    "list",
    "submitButton"
  ]
  static values = {
    allowedFileExtensions: Array
  }

  // Lifecycle

  async connect() {
    lockElement(this.element)
    this.cryptoHandler = await waitForCryptohandler(this.application)
    if (this.cryptoHandler) { unlockElement(this.element) }
  }

  // Entrypoints

  async checkSelectedFiles() {
    const anyInvalidFiles = await hasInvalidFiles(this.files, this.allowedFileExtensionsValue)

    if (anyInvalidFiles || hasTooManyFiles(this.files)) {
      this.showErrorMessage()
      this.clearFileInputs()
    } else {
      this.hideErrorMessage()
    }
  }

  async submit(event) {
    event.preventDefault()
    if (!this.cryptoHandler) { return }
    if (isBlank(this.contentTarget.value)) { return }

    dom.disableElement(this.submitButtonTarget)

    const fileUploadEncryption = new FileUploadEncryption({
      cryptoHandler: this.cryptoHandler,
      uploadUrl: this.encryptedFileInputTarget.dataset.directUploadUrl,
      form: this.formTarget,
      encryptedFileInputName: this.encryptedFileInputTarget.name
    })

    await fileUploadEncryption.prepareFiles(this.files, this.allowedFileExtensionsValue)
    await fileUploadEncryption.prepareEncryptedFileUpload()

    const url = this.formTarget.action
    const encryptedContent = await this.cryptoHandler.encryptText(this.contentTarget.value)
    const formData = new FormData(this.formTarget)

    formData.set(this.contentTarget.name, encryptedContent)

    const res = await fetch(url, {
      method: "POST",
      body: formData
    })

    fileUploadEncryption.clearHiddenFields()

    if (res.status === 200) {
      this.clearFileInputs()
      const responseJson = await res.json()
      this.onSuccess(responseJson)
    }
  }

  // Events

  onSuccess(responseJson) {
    const flashMessage = responseJson.flash_message
    const commentBody = responseJson.partial
    const messageCount = responseJson.message_count
    const messageCountSelector = responseJson.message_counter_selector
    const noteStatusBadgePartial = responseJson.note_status_badge_partial

    // We don't have to reset the file input as we already do that before submitting the form.
    this.contentTarget.value = ""

    const commentFragment = document.createRange().createContextualFragment(commentBody)
    this.listTarget.prepend(commentFragment)
    dom.enableElement(this.submitButtonTarget)

    // The message counter badge is not a child of this component, so we have to do this the old-fashioned way.
    const messageCounter = document.getElementById(messageCountSelector)
    if (messageCounter) {
      messageCounter.innerText = messageCount
      dom.showElement(messageCounter)
    }

    // Same for the status badge.
    const statusBadge = document.getElementById(DOM_IDS.noteStatusBadge)
    if (statusBadge && noteStatusBadgePartial) {
      const badgeFragment = document.createRange().createContextualFragment(noteStatusBadgePartial)
      statusBadge.replaceWith(badgeFragment)
    }

    // Turbo's caching after navigating away doesn't play nice with our updated views. It should navigate to the new page, show the cached version, fetch a new one and then update the view. But at least in my local dev environment it just cancels the network request after showing the cached version, thus reverting back to an earlier, now-wrong UI state (badge counters and potentially the status badge).
    // To prevent this, I marked all affected badges as data-turbo-permanent, but that breaks archiving and similar functionalities subtly as it no longer updates the badge to reflect the new status. We'd either have to update all these to also send a new partial, or find another way.
    // The current "other way" is to temporarily mark all links in the sidebar as non-turbo for a single navigation only to ensure the caches are reset properly.
    window.dispatchEvent(new CustomEvent("markSidebarLinksAsNonTurbo"))

    window.dispatchEvent(new CustomEvent("commentOrMessageSubmitted", {
      detail: {
        flash: {
          type: "notice",
          message: flashMessage
        }
      }
    }))
  }

  // DOM helpers

  clearFileInputs() {
    if (!this.hasFileInputTarget) { return [] }
    this.fileInputTargets.forEach(input => { input.value = null })
  }

  hideErrorMessage() {
    if (!this.hasErrorMessageTarget) return
    this.errorMessageTarget.classList.add("alert-info")
    this.errorMessageTarget.classList.remove("alert-danger")
  }

  showErrorMessage() {
    if (!this.hasErrorMessageTarget) return
    this.errorMessageTarget.classList.add("alert-danger")
    this.errorMessageTarget.classList.remove("alert-info")
  }

  // Getters

  get files() { return this.hasFileInputTarget ? Array.from(this.fileInputTarget.files) : [] }
}
