import { Controller } from "stimulus"
import FileSaver from "file-saver"
import { SymmetricCryptoHandler } from "../src/symmetric_crypto"
import * as base64 from "../src/base64"
import * as decryptedContentUI from "../src/note_decrypted_content_ui"
import * as dom from "../src/dom_helper"
import * as asymmetric_crypto from "../src/asymmetric_crypto"
import * as symmetricCrypto from "../src/symmetric_crypto"
import { SSID_PRIVATE_KEY } from "../config/storage_identifiers"

export default class extends Controller {
  static targets = ["display", "markAsSeenForm", "sourceElement"]

  // Lifecycle
  async connect() {
    const importSuccess = this.importEncryptedSource()
    if (!importSuccess) { return false }

    this.decryptedJson = await this.getDecryptedContent()
    if (!this.decryptedJson) { return false }

    await this.showDecryptedContent()
    this.markNoteAsSeen()
  }

  // Entrypoints

  async downloadDecryptedFile(event) {
    dom.stopPropagation(event)

    const spinnerEl = event.currentTarget.querySelector(".download-file-spinner")
    spinnerEl?.classList?.remove("d-none")
    const sourceUrl = event.currentTarget.dataset.url
    const filename = event.currentTarget.dataset.filename
    const decryptedBuffer = await this.cryptoHandler.decryptFileFromUrl(sourceUrl)
    spinnerEl?.classList?.add("d-none")
    FileSaver.saveAs(new Blob([decryptedBuffer]), filename)
  }

  // Private

  async showDecryptedContent() {
    if (!this.hasDisplayTarget) { return }

    // Clear existing content to prevent double renders after a back/forward navigation.
    this.displayTarget.innerHTML = ""

    decryptedContentUI.addNoteDataToContentUI(
      this.decryptedJson,
      this.encryptedDataFormatVersion,
      this.displayTarget
    )

    this.displayTarget.classList.remove("d-none")
  }

  async getDecryptedContent() {
    const privateKey = await this.loadPrivateKey()
    if (!privateKey) { return }

    const encryptionKeyJwk = JSON.parse(await asymmetric_crypto.decryptText(this.encryptedEncryptionKey, privateKey))

    this.cryptoHandler = new SymmetricCryptoHandler({
      encryptionKey: await symmetricCrypto.importEncryptionKey(encryptionKeyJwk),
      iv: this.iv
    })

    const decryptedData = await this.cryptoHandler.decryptText(this.encryptedJson)
    return JSON.parse(decryptedData)
  }

  importEncryptedSource() {
    if (!this.hasSourceElementTarget) { return }

    try {
      const source = JSON.parse(this.sourceElementTarget.innerText)
      this.iv = base64.base64ToBytes(source.iv)
      this.encryptedEncryptionKey = source.encryptedEncryptionKey
      this.encryptedJson = source.encryptedJson
      this.encryptedDataFormatVersion = source.encryptedDataFormatVersion

      return true
    } catch(e) {
      return false
    }
  }

  async loadPrivateKey() {
    const privateKeyJwkJsonDump = sessionStorage.getItem(SSID_PRIVATE_KEY)
    if (!privateKeyJwkJsonDump) { return }

    return await asymmetric_crypto.parsePrivateKey(JSON.parse(privateKeyJwkJsonDump))
  }

  markNoteAsSeen() {
    if (!this.hasMarkAsSeenFormTarget) { return }

    this.markAsSeenFormTarget.requestSubmit()
  }
}
