import { Controller } from "stimulus"
import * as asymmetricCrypto from "../src/asymmetric_crypto"
import { SSID_PRIVATE_KEY } from "../config/storage_identifiers"

export default class extends Controller {
  static targets = ["encryptedEncryptionKeyInput", "publicKey"]
  static values = { encryptedEncryptionKey: String }

  async addUser() {
    if (!this.hasEncryptedEncryptionKeyInputTarget) { return }

    const publicKey = await this.loadPublicKey()
    if (!publicKey) { return }

    const privateKey = await this.loadPrivateKey()
    if (!privateKey) { return }

    const encryptionKeyJwkDump = await asymmetricCrypto.decryptText(this.encryptedEncryptionKeyValue, privateKey)
    const encryptedEncryptionKeyJwkDump = await asymmetricCrypto.encryptText(encryptionKeyJwkDump, publicKey)

    this.encryptedEncryptionKeyInputTarget.value = encryptedEncryptionKeyJwkDump
    this.element.requestSubmit()
  }

  async loadPublicKey() {
    if (!this.hasPublicKeyTarget) { return }

    const publicKeyExport = JSON.parse(this.publicKeyTarget.innerText)
    return await asymmetricCrypto.parsePublicKey(publicKeyExport)
  }

  async loadPrivateKey() {
    const privateKeyJwkJsonDump = sessionStorage.getItem(SSID_PRIVATE_KEY)
    if (!privateKeyJwkJsonDump) { return }

    return await asymmetricCrypto.parsePrivateKey(JSON.parse(privateKeyJwkJsonDump))
  }
}
