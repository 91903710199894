import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["count", "repetition"]

    // callbacks

    connect() {
        this.countTarget.addEventListener('change', this.onCountChange.bind(this))
        this.showRepetitions(this.countTarget.value)
    }

    disconnect() {
        this.countTarget.removeEventListener('change', this.onCountChange)
    }

    // private

    onCountChange(e) {
        this.showRepetitions(e.target.value)
    }

    showRepetitions(count) {
        count = Number.parseInt(count)
        this.repetitionTargets.forEach((e, i) => {
            const show = i < count
            e.classList.toggle("d-none", !show)
            e.disabled = !show
        });
    }
}
