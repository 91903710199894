import sanitizeHtml from "sanitize-html"

const FALLBACK_LOCALE = "de"

export function addAlert(type, text, parent) {
  document.getElementById("js--alert")?.remove()

  const node = document.createElement("div")
  node.innerHTML = `
  <div class="alert alert-dismissible alert-${type}" role="alert" data-controller="flash" id="js--alert">
    <div class="container">
      ${text}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>
  `
  parent.appendChild(node)
}

export function addKeyValueDisplay(key, value, parent) {
  const node = document.createElement("div")
  const joinedValue = Array.isArray(value) ? value.join("\n") : value
  const sanitizedValue = sanitizeHtml(joinedValue, {
    allowedTags: [],
    disallowedTagsMode: "escape",
    allowedAttributes: false
  })
  const formattedValue = sanitizedValue.replaceAll("\n", "<br />")

  node.innerHTML = `<strong>${key}</strong><br /><p>${formattedValue}</p>`
  parent.appendChild(node)
}

export function addKeyValueRepeatCountHeader(index, parent) {
  const node = document.createElement("div")

  node.innerHTML = `<div class="fw-bold mt-5 mb-2 border-bottom">${index + 1}</div>`
  parent.appendChild(node)
}

export function disableElement(buttonElement) {
  if (!buttonElement) { return }

  buttonElement.disabled = true
  buttonElement.classList.add("disabled")
}

export function enableElement(buttonElement) {
  if (!buttonElement) { return }

  buttonElement.disabled = false
  buttonElement.classList.remove("disabled")
}

// This is adapted and fixed from https://github.com/danielcobo/getval/blob/master/src/index.js
export function getInputValue(element) {
  const type = element.getAttribute("type")
  const form = element.closest("form")

  // Radio and checkboxes don't provide a simple #value.
  if (type === "radio") {
    const checkedElement = form.querySelector(`input[name="${element.getAttribute('name')}"]:checked`)
    return checkedElement?.value

  } else if (type === "checkbox") {
    const checkedElements = [...form.querySelectorAll(`input[name="${element.getAttribute('name')}"]:checked`)]
    return checkedElements.map((checkedElement) => checkedElement.value)

  } else {
    // "Default" inputs like text(area) and select
    return element.value
  }
}

export function getLocaleFromBody() {
  return document.body.dataset.locale || FALLBACK_LOCALE
}

export function hideElement(element) {
  if (element) { element.classList.add("d-none") }
}

export function showElement(element) {
  if (element) { element.classList.remove("d-none") }
}

export function lockElement(element) {
  if (element) { element.classList.add("is--locked") }
}

export function unlockElement(element) {
  if (element) { element.classList.remove("is--locked") }
}

export function stopPropagation(event) {
  event.preventDefault()
  event.stopPropagation()
}
