import { Controller } from "stimulus"
import FileSaver from "file-saver"
import { getLocaleFromUrl } from "../src/helper"
import { SymmetricCryptoHandler } from "../src/symmetric_crypto"
import { SSID_PUBLIC_LOGIN_ERROR, SSID_PUBLIC_LOGIN_KEY, SSID_PUBLIC_NOTE_UUID } from "../config/storage_identifiers"
import * as base64 from "../src/base64"
import * as dom from "../src/dom_helper"
import * as decryptedContentUI from "../src/note_decrypted_content_ui"
import * as symmetricCrypto from "../src/symmetric_crypto"

const LOGIN_ERRORS = {
  emptyPassword: "emptyPassword",
  importFailure: "importFailure",
  decryptionFailure: "decryptionFailure"
}

const LOGIN_STATUS_SUCCESS = "success"

const LOGOUT_BUTTON_SELECTOR = ".btn--public-logout"

export default class extends Controller {
  static targets = [
    "content",
    "decryptedContent",
    "markAsSeenForm",
    "noteUiContent",
    "sourceElement",
    "uuid"
  ]

  static values = {
    uuid: String
  }

  // Lifecycle

  async connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.noteUiData = this.noteUiContentTarget.innerText

    const loginStatus = await this.unlockContent()
    if (loginStatus === LOGIN_STATUS_SUCCESS) { return }

    if (loginStatus !== LOGIN_ERRORS.emptyPassword) {
      sessionStorage.setItem(SSID_PUBLIC_LOGIN_ERROR, loginStatus)
    }

    sessionStorage.setItem(SSID_PUBLIC_NOTE_UUID, this.uuidValue)
    Turbo.visit(`/${getLocaleFromUrl()}/login`)
  }

  // Entrypoints

  async downloadDecryptedFile(event) {
    dom.stopPropagation(event)

    const spinnerEl = event.currentTarget.querySelector(".download-file-spinner")
    spinnerEl?.classList?.remove("d-none")
    const sourceUrl = event.currentTarget.dataset.url
    const filename = event.currentTarget.dataset.filename
    const decryptedBuffer = await this.cryptoHandler.decryptFileFromUrl(sourceUrl)
    spinnerEl?.classList?.add("d-none")
    FileSaver.saveAs(new Blob([decryptedBuffer]), filename)
  }

  async unlockContent() {
    const password = sessionStorage.getItem(SSID_PUBLIC_LOGIN_KEY)
    sessionStorage.removeItem(SSID_PUBLIC_LOGIN_KEY)
    if (!password) { return LOGIN_ERRORS.emptyPassword }

    const importSuccess = this.importEncryptedSource()
    if (!importSuccess) { return LOGIN_ERRORS.importFailure }

    const decryptedContent = await this.decryptContent(password)
    if (!decryptedContent) { return LOGIN_ERRORS.decryptionFailure }

    this.showContentUi(decryptedContent)
    this.markNoteAsSeen()

    return LOGIN_STATUS_SUCCESS
  }

  // Private

  async decryptContent(password) {
    try {
      if (!password) { return false }

      const encryptionKey = await symmetricCrypto.deriveEncryptionKeyFromPassword(password)
      if (!encryptionKey) { return false }

      this.cryptoHandler = new SymmetricCryptoHandler({
        encryptionKey,
        iv: this.iv
      })

      const decryptedData = await this.cryptoHandler.decryptText(this.encryptedJson)
      return JSON.parse(decryptedData)
    } catch (e) { return false }
  }

  importEncryptedSource() {
    if (!this.hasSourceElementTarget) { return false }

    try {
      const source = JSON.parse(this.sourceElementTarget.innerText)
      this.iv = base64.base64ToBytes(source.iv)
      this.encryptedJson = source.encryptedJson
      this.encryptedDataFormatVersion = source.encryptedDataFormatVersion

      return true
    } catch(e) {
      return false
    }
  }

  markNoteAsSeen() {
    if (!this.hasMarkAsSeenFormTarget) { return }

    this.markAsSeenFormTarget.requestSubmit()
  }

  // UI helpers

  showContentUi(decryptedContent) {
    this.contentTarget.innerHTML = this.noteUiData

    decryptedContentUI.addNoteDataToContentUI(
      decryptedContent,
      this.encryptedDataFormatVersion,
      this.decryptedContentTarget
    )

    dom.showElement(this.decryptedContentTarget)
    dom.showElement(document.querySelector(LOGOUT_BUTTON_SELECTOR))
    dom.unlockElement(document.querySelector("body"))
  }
}
