import { Controller } from "stimulus"
import { navigateTo } from "../src/navigation"

export default class extends Controller {
  static values = { redirectUrl: String }

  connect() {
    if (this.supportsSubtleCrypto()) { return }

    navigateTo(this.redirectUrlValue)
  }

  supportsSubtleCrypto() {
    try {
      if (window.crypto?.subtle) { return true }
    } catch(_e) {}

    return false
  }
}
