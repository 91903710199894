import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    content: String,
    event: String,
    confirmation: String,
  }
  static targets = ["content", "popover"]

  // Events
  copy() {
    const value = this.contentValue || this.contentTarget.innerText
    navigator.clipboard.writeText(value)
    this.element.dispatchEvent(new CustomEvent(this.eventValue, { bubbles: true }))

    const popoverEl = this.popoverElement || this.element
    const popover = new bootstrap.Popover(popoverEl, {
      offset: [0, 20],
      content: this.confirmationValue,
      title: '',
    })
    popover.show()
    setTimeout(() => popover.hide(), 2000)
  }
}
