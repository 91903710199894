import { Controller } from "stimulus"
import { SSID_PRIVATE_KEY, SSID_SECRET_KEY } from "../config/storage_identifiers"

export default class extends Controller {
  static targets = ["emailInput", "passwordInput", "secretKeyInput"]

  removePrivateKey() {
    sessionStorage.removeItem(SSID_PRIVATE_KEY)
    sessionStorage.removeItem(SSID_SECRET_KEY)
  }
}
