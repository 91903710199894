import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formElement"]
  static values = { disabled: Boolean }

  // lifecycle

  connect() {
    const url = new URL(window.location.href)
    this.categoryId = url.searchParams.get("category_id")
    this.organizationId = url.searchParams.get("organization_id")

    this.evaluateAllElements()
  }

  // Event handlers

  changed() { this.evaluateAllElements() }

  // Actions

  evaluateAllElements() {
    this.formElementTargets.forEach(el => this.hideOrShowBasedOnRules(el))
  }

  hideOrShowBasedOnRules(domEl) {
    if (this.disabledValue) return

    const element = FormElement.create(domEl)
    const rules = element.triggeredByFormRules
    const isHidden = rules.some(rule => this.isHiddenByRule(rule, domEl))

    if (isHidden) {
      element.hide()
    } else {
      element.show()
    }
  }

  isHiddenByRule(rule, domEl) {
    // If the rule has either a category_id or an organization_id, the field can only be hidden if they match. Otherwise the rule isn't active here and we can return early.
    if (rule.category_id && this.categoryId !== rule.category_id) { return false }
    if (rule.organization_id && this.organizationId !== rule.organization_id) { return false }

    const triggerDomEl = this.findTriggerElBubblingUp(rule.form_element_id, domEl)
    const triggerEl = FormElement.create(triggerDomEl)
    const triggerValues = triggerEl?.getValues() ?? []
    const isEmpty = triggerValues.length === 0
    const hasValue = triggerValues.includes(rule.form_option_id)

    switch (rule.condition_type) {
      case "does_not_have_value":
        return !hasValue
      case "has_value":
        return hasValue
      case "has_value_or_empty":
        return hasValue || isEmpty
      default:
        console.error("Unknown form rule condition type.", rule.condition_type)
        return false
    }
  }

  findTriggerElBubblingUp(id, domEl) {
    const triggerEl = domEl.querySelector(`[data-form-element-id="${id}"]`)
    return triggerEl ? triggerEl : this.findTriggerElBubblingUp(id, domEl.parentElement)
  }
}

class FormElement {
  constructor(el) {
    this.el = el
  }

  get formElementId() {
    return this.el.dataset.formElementId
  }

  get triggeredByFormRules() {
    return JSON.parse(this.el.dataset.triggeredByFormRules ?? "[]")
  }

  hide() {
    this.disabled = true
    this.el.classList.add("d-none")
    this.el.disabled = true
  }

  show() {
    this.disabled = false
    this.el.classList.remove("d-none")
    this.el.disabled = false
  }

  static create(el) {
    if (!el) return null

    switch (el.dataset.fieldType) {
      case "1": return new InputFormElement(el)
      case "2": return new TextareaFormElement(el)
      case "3": return new InputGroupFormElement(el)
      case "4": return new InputGroupFormElement(el)
      case "5": return new SelectFormElement(el)
      case "6": return new InputFormElement(el)
      default: return new DefaultFormElement(el)
    }
  }
}

class InputFormElement extends FormElement {
  set disabled(value) {
    this.el.querySelector("input").disabled = value
  }
}

class TextareaFormElement extends FormElement {
  set disabled(value) {
    this.el.querySelector("textarea").disabled = value
  }
}

class InputGroupFormElement extends FormElement {
  getValues() {
    return [...this.el
      .querySelectorAll("input:checked")]
      .map(input => input.value)
  }

  set disabled(value) {
    this.el.querySelectorAll("input").forEach(el => { el.disabled = value })
  }
}

class SelectFormElement extends FormElement {
  getValues() {
    const value = this.el.querySelector("select").value
    return value === "" ? [] : [value]
  }

  set disabled(value) {
    this.el.querySelector("select").disabled = value
  }
}

class DefaultFormElement extends FormElement {
  set disabled(value) {
    // do nothing
  }
}
