import * as base64 from "./base64"
import * as publicKeyCrypto from "./asymmetric_crypto"
import * as symmetricCrypto from "./symmetric_crypto"
import { getInputValue } from "./dom_helper"
import { FIELD_TYPES } from "../config/forms"

const FORM_ELEMENT_INPUT_GROUP_MARK = "formElementInputGroupParsed"
const FORM_ELEMENT_INPUT_GROUP_MARK_DATA_ATTRIBUTE = "data-form-element-input-group-parsed"

export const encryptFormData = async (serializedJson, encryptionKey, iv, publicKeys) => {
  // TODO: Error handling if no public keys were found/parsed (this should happen earlier and server side, though).
  if (publicKeys?.length == 0) { throw("No public keys found, can't submit form!") }

  const encryptedBase64 = await symmetricCrypto.encryptText(
    serializedJson,
    encryptionKey,
    iv
  )

  let submissionData = {
    iv: base64.bytesToBase64(iv),
    encryptedJson: encryptedBase64,
    encryptionKeys: []
  }

  submissionData.encryptionKeys = await Promise.all(publicKeys.map(async (publicKeyData) => {
    const exportedKey = await symmetricCrypto.exportEncryptionKey(encryptionKey)
    const exportedKeyJsonDump = JSON.stringify(exportedKey)
    const encryptedKey = await publicKeyCrypto.encryptText(exportedKeyJsonDump, publicKeyData.key)

    return {
      userId: publicKeyData.userId,
      keyId: publicKeyData.keyId,
      encryptedKey: encryptedKey
    }
  }))

  return JSON.stringify(submissionData)
}

export const generateEncryptionKey = async () => {
  const iv = symmetricCrypto.generateIV()
  const keyData = await symmetricCrypto.generateEncryptionKey()

  return {
    iv: iv,
    encryptionKey: keyData.encryptionKey,
    password: keyData.password
  }
}

export const formToJSON = (formElement) => {
  replaceValueIdsWithLabels(formElement)

  const objectFormData = formDataToObject(formElement)
  const reducedFormData = cleanupFormData(objectFormData)
  const jsonData = JSON.stringify(reducedFormData)

  return jsonData
}

// Internal helpers

const cleanupFormData = (formData) => {
  delete formData["_method"]
  delete formData["authenticity_token"]
  delete formData["commit"]
  delete formData["note[files][]"]
  delete formData["note[privacy_terms]"]
  delete formData["note[content]"]

  return formData
}

const formDataToObject = (form) => {
  const formElements = [...form.querySelectorAll(`[data-form-element-id]:not(div):not([disabled])`)]

  return formElements.reduce((existingData, currentElement) => {
    return mergeSameKeyIntoArrayReducer(form, existingData, currentElement)
  }, {})
}

const getConfigForFormElement = (formElement) => {
  const config = {
    label: formElement?.dataset?.nameLabel,
    fieldType: formElement?.dataset?.fieldType ? parseInt(formElement.dataset.fieldType) : null
  }

  if (!formElement) { return config }

  const parentRepeatingElement = formElement.closest(`[data-field-type="9"]`)
  const repetitionIndexElement = formElement.closest(`[data-documentation-form-block-type-value="repeatable"]`)
  if (!parentRepeatingElement) { return config }

  config.repeatingElementId = parentRepeatingElement.dataset.formElementId
  config.repetitionIndex = parseInt(repetitionIndexElement.dataset.repetitionIndex)

  return config
}

const mergeSameKeyIntoArrayReducer = (form, existingData, formElement) => {
  let formDataTarget = existingData
  const formElementId = formElement.dataset.formElementId
  const formElementConfig = getConfigForFormElement(formElement)
  const repeatingElementId = formElementConfig.repeatingElementId
  const repetitionIndex = formElementConfig.repetitionIndex
  const value = getInputValue(formElement)

  // getInputValue() returns an array containing all checked elements of a checkbox/radiobutton group.
  // We're iterating over all FormElements, so we're hitting a 3-option checkbox group 3 times. We only want to hit it once, though, so we check for a special mark amongst all options with the same name (= belonging to the same group). If we find it, we skip this element, otherwise we handle it as usual and mark it afterwards.
  if(formElementConfig.fieldType === FIELD_TYPES.checkBox || formElementConfig.fieldType === FIELD_TYPES.radioButton) {
    const markedElements = form.querySelectorAll(`[name="${formElement.name}"][${FORM_ELEMENT_INPUT_GROUP_MARK_DATA_ATTRIBUTE}]`)

    if (markedElements.length > 0) {
      return existingData
    } else {
      formElement.dataset[FORM_ELEMENT_INPUT_GROUP_MARK] = true
    }
  }

  // This creates a nested construct that essentially contains an array of existingData-structures, one for each repetition.
  if (repeatingElementId) {
    if (repeatingElementId in existingData === false) {
      existingData[repeatingElementId] = {
        fieldType: FIELD_TYPES.repeatable,
        data: []
      }
    }

    // Initialize the empty existingData structure in our nested array.
    if (!existingData[repeatingElementId].data[repetitionIndex]) {
      existingData[repeatingElementId].data[repetitionIndex] = {}
    }

    formDataTarget = existingData[repeatingElementId].data[repetitionIndex]
  }

  if (formElementId in formDataTarget) {
    formDataTarget[formElementId].data.push(value)
  } else {
    formDataTarget[formElementId] = {
      label: formElementConfig.label,
      data: Array.isArray(value) ? value : [value],
      fieldType: formElementConfig.fieldType
    }
  }

  // We need to return existingData since formDataTarget might just be a nested part of the whole.
  return existingData
}

const replaceValueIdsWithLabels = (form) => {
  form.querySelectorAll("input[data-value-label], option[data-value-label]").forEach(el => {
    el.value = el.dataset.valueLabel
  })
}
