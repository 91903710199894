function findFirst(children, predicateFn) {
    let result
    children.find(child => {
        result = findNested(child, predicateFn)
        return !!result
    })
    return result
}

export function findNested(el, predicateFn) {
    if (!el) { return false }
    if (predicateFn(el)) { return el }
    if (Array.isArray(el)) { return findFirst(el, predicateFn) }
    if (typeof el === "object") { return findFirst(Object.values(el), predicateFn) }

    return false
}

export function findAllNested(el, predicateFn) {
    let results = []
    if (!el) { return results }

    if (predicateFn(el)) { results.push(el) }
    if (Array.isArray(el)) {
        el.map(entry => {
            const subResults = findAllNested(entry, predicateFn).flat(10)
            if (subResults.length > 0) { results.push(subResults) }
        })
    } else if (typeof el === "object") {
        Object.values(el).map(entry => {
            const subResults = findAllNested(entry, predicateFn).flat(10)
            if (subResults.length > 0) { results.push(subResults) }
        })
    }

    return results.flat(10)
}