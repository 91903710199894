import { Controller } from "stimulus"
import { SSID_SESSION_TIMEOUT_TIMESTAMP } from "../config/storage_identifiers"


export default class extends Controller {
  setSessionTimestamp() {
    // This has to be one of the first things we do to ensure it is set in all use cases
    sessionStorage.setItem(SSID_SESSION_TIMEOUT_TIMESTAMP, Date.now().toString())
  }
}
