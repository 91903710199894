import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    failureMessage: String,
    fileId: String,
  }

  // Events
  handleResponse(event) {
    if (!event.srcElement.action.includes(this.fileIdValue)) { return }

    if (event.detail.fetchResponse.response.ok) {
      this.element.remove()
    } else {
      alert(this.failureMessageValue)
    }
  }
}
