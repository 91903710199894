import { Controller } from "stimulus"
import * as base64 from "../src/base64"
import * as dom from "../src/dom_helper"
import { isBlank } from "../src/helper"
import {
  SECRET_KEY_ERRORS,
  emptyStoredAccountData,
  isSecretKeyHandlingError,
  parseStoredAccountData,
  storeEncryptedAccountData
} from "../src/account_data_handling"
import * as symmetric_crypto from "../src/symmetric_crypto"
import {
  LSID_ACCOUNT_DATA,
  LSID_SECRET_KEY,
  SSID_SECRET_KEY,
  SSID_PRIVATE_KEY,
  SSID_SESSION_TIMEOUT_TIMESTAMP
} from "../config/storage_identifiers"

async function fetchKeyPair(email, password) {
  const url = `/encrypted_keys`
  let parsedResponseBody

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      session: {
        email: email,
        password: password
      }
    })
  })

  try {
    parsedResponseBody = await response.json()
  } catch(e) {}

  return {
    success: response.ok,
    data: parsedResponseBody
  }
}

export default class extends Controller {
  static targets = [
    "alertContainer",
    "emailInput",
    "form",
    "passwordInput",
    "secretKeyForm",
    "secretKeyInput",
    "storedAccountForm",
    "storedEmailInput",
    "submitButton"
  ]
  static values = { errorMessage: String }

  // Lifecycle

  connect() {
    // Firefox doesn't play nice with async requests during a form submit, it justs sends both in parallel. This breaks our flow as the request for the public key is cut short by the actual form submission. So we cancel the form submission the first time it is submitted to do our setup process, set the flag and then trigger a new submit that goes through.
    this.hasFetchedPublicKey = false

    this.clearStoredAccountData()
    this.parseStoredAccountData()
    this.secretKey = null

    this.updateUi()

    // Legacy secret key migration.
    // If the User still has their secret key stored in LocalStorage, we prefill the field with its value if it is visible.
    this.loadLegacySecretKey()
  }

  // Entrypoints / Handlers

  changeAccount() {
    this.clearStoredAccountData()
    localStorage.removeItem(LSID_ACCOUNT_DATA)
    Turbo.visit(window.location.href)
  }

  async generateEncryptionKey(event) {
    // This has to be one of the first things we do to ensure it is set in all use cases
    sessionStorage.setItem(SSID_SESSION_TIMEOUT_TIMESTAMP, Date.now().toString())

    // If this is NOT the first time we have reached this code, all the decryption actions, public key retrieval, etc. have already been done so we don't have to do anything anymore.
    if (this.hasFetchedPublicKey) { return }
    // If this IS the first, time, though, stop the form submittal so we have time to fetch the public key etc.
    dom.stopPropagation(event)
    dom.disableElement(this.submitButtonTarget)

    // Attempt to decrypt a stored secret key, if present.
    if (this.storedAccountData.encryptedSecretKey) {
      let secretKeyOrError = await this.decryptStoredSecretKey()
      // If this failed, show an error and stop. Caveat: a falsy return value is okay, as this simply indicates there was no key to decrypt.
      if (isSecretKeyHandlingError(secretKeyOrError)) {
        this.abortLogin(event)
        return
      }

      this.secretKey = secretKeyOrError
    } else {
      this.secretKey = this.secretKeyInputValue
    }

    // We used to allow Users to log in without a secret key in case they had no active KyPair and thus no secret key to decrypt or enter. This is now explicitly no longer allowed. Users without a KeyPair should ask their admins to send them a reset link and go through the regular flow.
    if (!this.secretKey) {
      this.abortLogin(event)
      return
    }

    let privateKeyJwkJsonDump
    const response = await fetchKeyPair(this.email, this.password)

    if (!response || !response.success) {
      this.abortLogin(event, response.data?.error_message)
      return
    }

    try {
      const mergedKey = `${this.password}${this.secretKey}`
      const encryptionKey = await symmetric_crypto.deriveEncryptionKeyFromPassword(mergedKey)
      const iv = base64.base64ToBytes(response.data.encryption_iv)

      privateKeyJwkJsonDump = await symmetric_crypto.decryptText(response.data.encrypted_private_key, encryptionKey, iv)
    } catch(e) {
      this.abortLogin(event)
      return
    }

    const potentialEncryptionError = await storeEncryptedAccountData({
      secretKey: this.secretKey,
      email: this.email,
      password: this.password
    })

    if (isSecretKeyHandlingError(potentialEncryptionError)) {
      this.abortLogin(event)
      return
    }

    sessionStorage.setItem(SSID_PRIVATE_KEY, privateKeyJwkJsonDump)
    sessionStorage.setItem(SSID_SECRET_KEY, this.secretKey)

    this.hasFetchedPublicKey = true
    dom.stopPropagation(event)
    dom.enableElement(this.submitButtonTarget)
    this.formTarget.requestSubmit()
  }

  // Secret key handling

  async decryptStoredSecretKey() {
    try {
      const parts = this.storedAccountData.encryptedSecretKey.split(".")
      const encryptedSecretKey = parts[0]
      const iv = base64.base64ToBytes(parts[1])

      const encryptionKey = await symmetric_crypto.deriveEncryptionKeyFromPassword(this.password)
      const secretKey = await symmetric_crypto.decryptText(encryptedSecretKey, encryptionKey, iv)

      return secretKey
    } catch(e) { return SECRET_KEY_ERRORS.invalidPassword }
  }

  isSecretKeyHandlingError(result) {
    return !!SECRET_KEY_ERRORS[result]
  }

  async storeEncryptedSecretKey() {
    return storeEncryptedAccountData({
      secretKey: this.secretKey,
      email: this.email,
      password: this.password
    })
  }

  // Legacy secret key handling / soft migration

  loadLegacySecretKey() {
    if (this.hasStoredAccountData()) { return }

    const legacySecretKey = localStorage.getItem(LSID_SECRET_KEY)
    if (!legacySecretKey) { return }

    this.secretKeyInputTarget.value = legacySecretKey
  }

  // UI handling

  updateUi() {
    if (this.hasStoredAccountData()) {
      this.storedAccountFormTargets.forEach(elem => elem.classList.remove("d-none"))
      this.secretKeyFormTargets.forEach(elem => elem.classList.add("d-none"))
      this.secretKeyInputTarget.required = false
    } else {
      this.storedAccountFormTargets.forEach(elem => elem.classList.add("d-none"))
      this.secretKeyFormTargets.forEach(elem => elem.classList.remove("d-none"))
      this.secretKeyInputTarget.required = true
    }
  }

  // Helpers

  abortLogin(event, serverSideErrorMessage) {
    dom.stopPropagation(event)
    dom.enableElement(this.submitButtonTarget)
    this.showAlert(serverSideErrorMessage)
  }

  clearStoredAccountData() {
    this.storedAccountData = emptyStoredAccountData()
  }

  hasStoredAccountData() {
    return !!(this.storedAccountData.encryptedSecretKey && this.storedAccountData.email)
  }

  parseStoredAccountData() {
    this.storedAccountData = { ...parseStoredAccountData() }

    if (this.storedAccountData.email) {
      // We hide the email form field when stored account data is present. Since we're using the regular Rails form submission process for the server-side login, though, we need to make sure the field contains the correct value.
      this.emailInputTarget.value = this.storedAccountData.email
      // This is the fake display for UI/information purposes only.
      this.storedEmailInputTarget.value = this.storedAccountData.email
    }
  }

  showAlert(serverSideErrorMessage) {
    const errorMessage = isBlank(serverSideErrorMessage) ? this.errorMessageValue : serverSideErrorMessage
    dom.addAlert("warning", errorMessage, this.hasAlertContainerTarget ? this.alertContainerTarget : this.element)
  }

  // Getters

  get email() {
    if (!this.hasEmailInputTarget) { return }
    if (!this.emailInputTarget.value) { return }

    return this.emailInputTarget.value
  }

  get secretKeyInputValue() {
    if (!this.hasSecretKeyInputTarget) { return }
    if (!this.secretKeyInputTarget.value) { return }

    return symmetric_crypto.normalizePassword(this.secretKeyInputTarget.value)
  }

  get password() {
    if (!this.hasPasswordInputTarget) { return }
    if (!this.passwordInputTarget.value) { return }

    return this.passwordInputTarget.value
  }
}
