import { Controller } from "stimulus"

const BOOTSTRAP_CSS_CLASS_LOOKUP = {
  notice: "alert-primary",
  success: "alert-primary",
  error: "alert-danger",
  warning: "alert-warning",
  alert: "alert-warning"
}

const BOOTSTRAP_CSS_CLASS_FALLBACK = "alert-primary"

export default class extends Controller {
  static targets = ["container", "template"]

  addFlash(event) {
    const flashData = event.detail?.flash
    if (!flashData) { return }

    this.createFlash(flashData.message, flashData.type)
  }

  createFlash(message, type) {
    const cssClass = BOOTSTRAP_CSS_CLASS_LOOKUP[type] || BOOTSTRAP_CSS_CLASS_FALLBACK
    const flashFragment = document.createRange().createContextualFragment(this.templateTarget.innerHTML)

    flashFragment.querySelector(".alert").classList.add(cssClass)
    flashFragment.querySelector(".alert-message").innerHTML = message

    this.containerTarget.prepend(flashFragment)
  }
}
