import { Controller } from "stimulus"

import { waitForCryptohandler } from "../src/crypto_handler_importer"

export default class extends Controller {
  static targets = [
    "setText",
    "setValue"
  ]

  static values = {
    encryptedText: String,
  }

  async connect() {
    this.cryptoHandler = await waitForCryptohandler(this.application)
    if (!this.cryptoHandler) { return }
    if (!this.encryptedTextValue) { return }

    await this.decryptText()
  }

  async decryptText() {
    if (!this.encryptedTextValue) { return }

    try {
      const decryptedText = await this.cryptoHandler.decryptText(this.encryptedTextValue)

      this.setTextTargets.forEach(target => target.innerText = decryptedText)
      this.setValueTargets.forEach(target => target.value = decryptedText)
    } catch(e) { console.error("Failed to decrypt message", e) }
  }
}
