import { Controller } from "stimulus"
import * as asymmetricCrypto from "../src/asymmetric_crypto"
import { SSID_PRIVATE_KEY } from "../config/storage_identifiers"
import { lockElement } from "../src/dom_helper"

export default class extends Controller {
  static targets = [
    "currentCounter",
    "encryptedEncryptionKeysInput",
    "form",
    "progressBar",
    "sourceData"
  ]
  static values = { totalCount: Number }

  async grantAccess() {
    this.currentCount = 0

    const privateKey = await this.loadPrivateKey()
    if (!privateKey) { return }

    const importStatus = await this.loadData()
    if (!importStatus) { return }

    this.encryptSymmetricKeys(privateKey)
  }

  async loadData() {
    if (!this.hasSourceDataTarget) { return }

    try {
      const sourceData = JSON.parse(this.sourceDataTarget.innerText)
      const publicKeyJwk = JSON.parse(sourceData.public_key)
      this.publicKey = await asymmetricCrypto.parsePublicKey(publicKeyJwk)
      this.noteData = sourceData.note_data
      return true
    } catch(e) {
      return false
    }
  }

  async loadPrivateKey() {
    const privateKeyJwkJsonDump = sessionStorage.getItem(SSID_PRIVATE_KEY)
    if (!privateKeyJwkJsonDump) { return }

    return await asymmetricCrypto.parsePrivateKey(JSON.parse(privateKeyJwkJsonDump))
  }

  async encryptSymmetricKeys(privateKey) {
    lockElement(this.element)

    const encryptedData = await Promise.all(this.noteData.map(async (noteData) => {
      const encryptionKeyJwkDump = await asymmetricCrypto.decryptText(noteData.encryptedEncryptionKey, privateKey)
      const encryptedEncryptionKeyJwkDump = await asymmetricCrypto.encryptText(encryptionKeyJwkDump, this.publicKey)

      this.increaseProgress()

      return {
        noteId: noteData.noteId,
        encryptedEncryptionKey: encryptedEncryptionKeyJwkDump
      }
    }))

    this.encryptedEncryptionKeysInputTarget.value = JSON.stringify(encryptedData)
    this.formTarget.requestSubmit()
  }

  increaseProgress() {
    this.currentCount += 1
    const progressPercentage = Math.round((this.currentCount / this.totalCountValue) * 100)

    this.currentCounterTarget.innerText = this.currentCount
    this.progressBarTarget.style.width = `${progressPercentage}%`
  }
}
