import { Controller } from "stimulus"

export default class extends Controller {
  static values = { permanent: Boolean }
  connect() {
    if (this.permanentValue) { return }

    setTimeout(() => { $(this.element).fadeOut() }, 10000)
  }
}
