import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    target: String
  }

  toggleTargets() {
    if (!this.targetNodes) { return }

    this.targetNodes.forEach(targetNode => targetNode.classList.toggle("is--disabled"))
  }

  get targetNodes() {
    if (!this.targetValue) { return [] }

    return document.querySelectorAll(this.targetValue)
  }
}
