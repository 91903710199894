import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["label", "editButton", "form"]

  // callbacks

  connect() {
    if (!this.hasFormTarget) { return }

    this.initialFormData = new FormData(this.formTarget)
  }

  showForm() {
    if (!this.hasFormTarget) { return }

    this.formTarget.classList.remove("d-none")
    this.labelTarget.classList.add("d-none")
    this.editButtonTarget.classList.add("d-none")
  }

  hideForm() {
    if (!this.hasFormTarget) { return }

    this.formTarget.classList.add("d-none")
    this.labelTarget.classList.remove("d-none")
    this.editButtonTarget.classList.remove("d-none")

    // TODO Support other inline form element types
    this.resetTextInputs()
    this.resetSelects()
  }

  // private

  resetTextInputs() {
    this.formTarget.querySelectorAll("input[type=text]").forEach(inputEl => {
      inputEl.value = this.initialFormData.get(inputEl.name)
    })
  }

  resetSelects() {
    this.formTarget.querySelectorAll("select").forEach(selectEl => {
      const values = this.initialFormData.getAll(selectEl.name);
      [...selectEl.options].forEach(option => option.selected = values.includes(option.value))
    })
  }
}
