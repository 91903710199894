import { Controller } from "stimulus"
import { SSID_PRIVATE_KEY, SSID_SECRET_KEY, SSID_SESSION_TIMEOUT_TIMESTAMP } from "../config/storage_identifiers"
import { isSecretKeyHandlingError, storeEncryptedAccountData } from "../src/account_data_handling"
import * as crypto from "../src/asymmetric_crypto"
import { bytesToBase64 } from "../src/base64"
import { disableElement, enableElement } from "../src/dom_helper"
import * as symmetricCrypto from "../src/symmetric_crypto"

const ERROR_MESSAGE_CONFIRMATION_MISMATCH = {
  de: "Die Passwörter stimmen nicht überein.",
  en: "The password confirmation does not match the password."
}
// Keep this in sync with models/user.rb
const PASSWORD_MINIMUM_LENGTH = 12

const isValidPassword = ({ password, hasPasswordConfirmation, passwordConfirmation }) => {
  if (!password) { return false }
  if (password.length < PASSWORD_MINIMUM_LENGTH) { return false }
  if (!isValidPasswordConfirmation({ password, hasPasswordConfirmation, passwordConfirmation })) { return false }

  return true
}

const isValidPasswordConfirmation = ({ password, hasPasswordConfirmation, passwordConfirmation }) => {
  if (!hasPasswordConfirmation) { return true }
  if (password !== passwordConfirmation) { return false }

  return true
}

export default class extends Controller {
  static targets = [
    "encryptedPrivateKeyInput",
    "encryptionIvInput",
    "form",
    "passwordConfirmationInput",
    "passwordInput",
    "publicKeyInput",
    "submitButton",
  ]
  static values = {
    email: String,
    name: String
  }

  connect() {
    // We use this flag to "detach" our generateKey() action from the form submit. Firefox doesn't play nice with async calls in a submit handler, so we stop the event on the first run, do our thing and then requestSubmit() a second time, this time doing nothing and simply letting the event go through.
    this.hasGeneratedKeyPair = false
  }

  async generateKey(event) {
    // We need to manually trigger this to ensure that the validity is re-checked after it has been invalid once.
    this.formTarget.checkValidity()
    this.formTarget.reportValidity()

    if (!this.isValidPassword()) {
      event.preventDefault()
      event.stopPropagation()

      if (!this.isValidPasswordConfirmation()) { this.showPasswordConfirmationError() }

      return
    }

    disableElement(this.submitButtonTarget)
    if (this.hasGeneratedKeyPair) { return }

    try {
      event.preventDefault()
      event.stopPropagation()

      const password = this.passwordInputTarget.value
      if (!password) { return }

      const secretKey = await symmetricCrypto.generateSecretKey()
      const mergedKey = `${password}${secretKey}`
      const encryptionkey = await symmetricCrypto.deriveEncryptionKeyFromPassword(mergedKey)

      const keyPair = await crypto.generateKey()
      const publicKeyDump = JSON.stringify(await crypto.exportKey(keyPair.publicKey))
      const privateKeyDump = JSON.stringify(await crypto.exportKey(keyPair.privateKey))

      const iv = symmetricCrypto.generateIV()
      const encryptedPrivateKey = await symmetricCrypto.encryptText(privateKeyDump, encryptionkey, iv)

      if (this.hasPublicKeyInputTarget) { this.publicKeyInputTarget.value = publicKeyDump }
      if (this.hasEncryptedPrivateKeyInputTarget) { this.encryptedPrivateKeyInputTarget.value = encryptedPrivateKey }
      if (this.hasEncryptionIvInputTarget) { this.encryptionIvInputTarget.value = bytesToBase64(iv) }

      sessionStorage.setItem(SSID_SECRET_KEY, secretKey)
      sessionStorage.setItem(SSID_PRIVATE_KEY, privateKeyDump)
      sessionStorage.setItem(SSID_SESSION_TIMEOUT_TIMESTAMP, Date.now().toString())

      const potentialEncryptionError = await storeEncryptedAccountData({
        secretKey: secretKey,
        email: this.emailValue,
        password: password
      })

      if (isSecretKeyHandlingError(potentialEncryptionError)) {
        this.abortKeyGeneration()
        return
      }

      this.hasGeneratedKeyPair = true
      this.formTarget.requestSubmit()

    } catch(e) {
      this.abortKeyGeneration()
    }
  }

  abortKeyGeneration() {
    this.hasGeneratedKeyPair = false
    enableElement(this.submitButtonTarget)
  }

  isValidPassword() {
    const passwordConfirmation = this.hasPasswordConfirmationInputTarget ? this.passwordConfirmationInputTarget?.value : null

    return isValidPassword({
      password: this.passwordInputTarget.value,
      hasPasswordConfirmation: this.hasPasswordConfirmationInputTarget,
      passwordConfirmation: passwordConfirmation
    })
  }

  isValidPasswordConfirmation() {
    const passwordConfirmation = this.hasPasswordConfirmationInputTarget ? this.passwordConfirmationInputTarget?.value : null

    return isValidPasswordConfirmation({
      password: this.passwordInputTarget.value,
      hasPasswordConfirmation: this.hasPasswordConfirmationInputTarget,
      passwordConfirmation: passwordConfirmation
    })
  }

  showPasswordConfirmationError() {
    if (!this.hasPasswordConfirmationInputTarget) { return }

    const locale = document.querySelector("body").dataset.locale || "de"
    const errorMessage = ERROR_MESSAGE_CONFIRMATION_MISMATCH[locale] ?? ERROR_MESSAGE_CONFIRMATION_MISMATCH["de"]

    this.passwordConfirmationInputTarget.setCustomValidity(errorMessage)
    this.formTarget.reportValidity()
  }

  resetPasswordConfirmationError() {
    this.passwordConfirmationInputTarget.setCustomValidity("")
  }
}
