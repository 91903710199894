import { Controller } from "stimulus"
import { enableElement, showElement } from "../src/dom_helper"
import { SSID_SECRET_KEY } from "../config/storage_identifiers"

export default class extends Controller {
  static targets = [
    "confirmationIcon",
    "secretKeyDisplay",
  ]
  static values = {
    email: String,
    name: String
  }

  connect() {
    if (!this.hasSecretKeyDisplayTarget) { return }

    const secretKey = sessionStorage.getItem(SSID_SECRET_KEY)
    this.secretKeyDisplayTarget.innerHTML = secretKey
    this.hasDownloadedEmergencyKit = false
  }

  markEmergencyKitAsDownloaded() {
    if (this.hasConfirmationIconTarget) { showElement(this.confirmationIconTarget) }
  }
}
