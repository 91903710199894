// The dropdown functionality is already implemented in Bootstrap. For some reason, however, the German accessibility guidelines require the locale selection dropdown to also open on space bar presses instead of click/enter. So we jst handle keyboard inputs on the focused element and click() it when the space bar has been pressed, otherwise doing nothing at all.
import { Controller } from "stimulus"

export default class extends Controller {
  handleKey(event) {
    if (event.key !== " ") { return }

    event.preventDefault()
    event.currentTarget.click()
  }
}
