import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["input"]
    static values = {
        required: Boolean
    }

    // event handlers
    changed(event) {
        this.toggleRequired()
    }

    // internal
    toggleRequired() {
        const someChecked = this.inputTargets.some(el => el.checked === true)

        this.inputTargets.forEach(input => {
            input.required = this.requiredValue && !someChecked
        })
    }
}
