// Fun with CSP nonces: The nonce is stored in the internal node repesentation, so a cached <script> tag doesn't have the actual nonce="..." HTML attribute anymore. To fix this, we manually assign it again.
document.addEventListener("turbo:before-cache", function() {
  document.querySelectorAll("script[nonce], style[nonce]").forEach(element => {
    element.nonce = element.setAttribute("nonce", element.nonce)
  })
})

document.addEventListener("turbo:before-fetch-request", (event) => {
  // Turbo Drive does not send a referrer like turbolinks used to, so we simluate it here and add the current CSP nonce.
  event.detail.fetchOptions.headers["TURBO-REFERRER"] = window.location.href
  event.detail.fetchOptions.headers["X-TURBO-NONCE"] = document.querySelector(`meta[name="csp-nonce"]`).content
})
