import { Controller } from "stimulus"
import * as dom from "../src/dom_helper"
import * as publicKeyCrypto from "../src/asymmetric_crypto"
import { SymmetricCryptoHandler } from "../src/symmetric_crypto"
import { encryptFormData, formToJSON, generateEncryptionKey } from "../src/note_encryption"
import { FileUploadEncryption } from "../src/file_upload_encryption"
import { SSID_PUBLIC_ENCRYPTION_PASSWORD } from "../config/storage_identifiers"

// The actual Stimulus controller
export default class extends Controller {
  static targets = [
    "contentInput",
    "encryptedFileInput",
    // This is the form we are finally going to submit, containing only encrypted data.
    "form",
    // These are the source file inputs which we are going to use to read the files and encrypt them.
    "fileInput",
    "submitButton",
    "userPublicKey"
  ]
  static values = {
    allowedFileExtensions: Array,
    isPublic: Boolean
  }

  // Entrypoints

  async encryptFormDataAndSubmitForm(event) {
    dom.stopPropagation(event)
    dom.disableElement(this.submitButtonTarget)
    await this.importPublicKeys()

    const { encryptionKey, iv, password } = await generateEncryptionKey()
    const cryptoHandler = new SymmetricCryptoHandler({
      encryptionKey: encryptionKey,
      iv: iv
    })
    // Users submitting internal Notes don't need the password to be stored in SessionStorage since they already have their private key in there which is used to decrypt the encrypted encryptionKey.
    if (this.isPublic) { sessionStorage.setItem(SSID_PUBLIC_ENCRYPTION_PASSWORD, password) }

    const fileUploadEncryption = new FileUploadEncryption({
      cryptoHandler: cryptoHandler,
      uploadUrl: this.encryptedFileInputTarget.dataset.directUploadUrl,
      form: this.formTarget,
      encryptedFileInputName: this.encryptedFileInputTarget.name
    })

    await fileUploadEncryption.prepareFiles(this.files, this.allowedFileExtensionsValue)
    await fileUploadEncryption.prepareEncryptedFileUpload()
    this.clearFileInputs()

    const serializedJson = formToJSON(event.target)
    await this.setEncryptedFormData(serializedJson, encryptionKey, iv)

    this.hasEncryptedFormData = true
    this.formTarget.requestSubmit()
  }

  ignoreSubmitAction(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  // Private

  async setEncryptedFormData(serializedJson, encryptionKey, iv) {
    const encryptedData = await encryptFormData(
      serializedJson,
      encryptionKey,
      iv,
      this.publicKeys
    )

    this.setFormFieldContent(encryptedData)
  }

  // Public Key import helpers

  async importPublicKeys() {
    this.publicKeys = []
    if (!this.hasUserPublicKeyTarget) { return }

    this.userPublicKeyTargets.map((element) => {
      try {
        const keyData = JSON.parse(element.innerText)
        this.setPublicKey(keyData)
      } catch(e) {}
    })
  }

  async setPublicKey(keyData) {
    const publicKeyExport = JSON.parse(keyData.public_key)
    const publicKey = await publicKeyCrypto.parsePublicKey(publicKeyExport)

    this.publicKeys.push({
      userId: keyData.user_id,
      keyId: keyData.key_id,
      key: publicKey
    })
  }

  // Form helpers

  clearFileInputs() {
    if (!this.hasFileInputTarget) { return [] }
    this.fileInputTargets.forEach(input => { input.value = null })
  }

  setFormFieldContent(textContent) {
    if (!this.hasContentInputTarget) { return }
    this.contentInputTarget.value = textContent
  }

  // Getters

  get files() {
    if (!this.hasFileInputTarget) { return [] }

    const files = []

    this.fileInputTargets.forEach(input => {
      Array.from(input.files).forEach(file => files.push(file))
    })

    return files
  }

  get isPublic() {
    if (!this.hasIsPublicValue) { return false }
    return this.isPublicValue
  }
}
