import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "btn",
    "btnBackgroundColor",
    "btnTextColor",
    "form",
    "link",
    "linkColor",
    "logoUrlRadioButton",
    "name",
    "nav",
    "navBackgroundColor",
    "navTextColor",
    "websiteUrlSource",
    "websiteUrlTarget"
  ]

  static values = {
    validatePath: String
  }

  // lifecycle

  connect() {
    this.generateColors()
    this.changeName()
    this.copyWebsiteUrl()
    this.logosFound()
  }

  // callbacks

  generateColors() {
    const navBackgroundColor = this.navBackgroundColorTarget.value
    const btnBackgroundColor = this.btnBackgroundColorTarget.value
    const linkColor = btnBackgroundColor
    const navTextColor = this.invertColor(navBackgroundColor)
    const btnTextColor = this.invertColor(btnBackgroundColor)

    this.linkColorTarget.value = linkColor
    this.navTextColorTarget.value = navTextColor
    this.btnTextColorTarget.value = btnTextColor

    this.navTarget.style = `color: ${navTextColor}; background-color: ${navBackgroundColor};`
    this.linkTargets.forEach(el => { el.style = `color: ${linkColor};` })
    this.btnTargets.forEach(el => { el.style = `color: ${btnTextColor}; background-color: ${btnBackgroundColor};` })
  }

  changeName() {
    this.navTarget.innerHTML = this.nameTarget.value
  }

  copyWebsiteUrl() {
    this.websiteUrlTargetTarget.value = this.websiteUrlSourceTarget.value
  }

  async validateSubdomain(e) {
    e.preventDefault()

    const data = new FormData(this.formTarget)
    const path = this.validatePathValue
    const method = this.formTarget.method

    const res = await fetch(path, { body: data, method: method })
    if (!res.ok) {
      this.formTarget.requestSubmit()
    }
  }

  logoUploadSelected() {
    if (this.hasLogoUrlRadioButtonTarget) {
      this.logoUrlRadioButtonTargets.forEach(element => element.checked = false)
    }
  }

  // private

  logosFound() {
    // Automatically select the first available logo to ensure we always/usually have a valid selection.
    if (this.hasLogoUrlRadioButtonTarget) {
      this.logoUrlRadioButtonTargets[0].checked = true
    }
  }

  invertColor(hex) {
    hex = hex.startsWith("#") ? hex.substring(1) : hex
    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF'
  }
}
