// LocalStorage
export const LSID_ACCOUNT_DATA = "accountData"
// Legacy for migrating to the new login system. Only used to read out the legacy key.
export const LSID_SECRET_KEY = "secretKey"

// SessionStorage
export const SSID_PRIVATE_KEY = "privateKey"
export const SSID_PUBLIC_ENCRYPTION_PASSWORD = "dicewareEncryptionKey"
export const SSID_SECRET_KEY = "secretKey"
export const SSID_SESSION_TIMEOUT_TIMESTAMP = "sessionTimeoutTimestamp"
export const SSID_TIMEOUT_TIMESTAMP = "timeoutTimestamp"

// This is a temporary storage to survive a page transition between the new login page and the Note details.
export const SSID_PUBLIC_LOGIN_KEY = "publicLoginKey"
// This is similar but in the other direction: from Note to login. Legacy Notes direclty linked the User to .../notes/UUID where they were asked only for the secret key, not the new UUID#SK combination. SO they will access a locked page, get redirected to the login view and then have no UUID to enter. So we store the UUID and accept a SK without UUID afterwards.
export const SSID_PUBLIC_NOTE_UUID = "publicNoteUuid"
export const SSID_PUBLIC_LOGIN_ERROR = "publicLoginError"
