import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["role", "categories", "organizations"]
  static values = { allowCategoriesAndOrganizationsForRoles: Array }

  // lifecycle
  connect() {
    this.showCategoriesAndOrganizations()
  }

  // events
  onChange(event) {
    this.showCategoriesAndOrganizations()
  }

  // internal
  showCategoriesAndOrganizations() {
    if (!this.hasRoleTarget) {
      return
    }
    
    const role = this.roleTarget.value
    const visible = this.allowCategoriesAndOrganizationsForRolesValue.includes(role)

    if (visible) {
      this.roleDependentTargets.forEach(e => { e.classList.remove("d-none") })
    } else {
      this.roleDependentTargets.forEach(e => { e.classList.add("d-none") })
    }
  }

  get roleDependentTargets() {
    // Use plural to avoid error if target is missing
    return [...this.categoriesTargets, ...this.organizationsTargets]
  }
}
