import { Controller } from "stimulus"
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import "bootstrap"

import { FIELD_TYPES } from "../config/forms"
import { waitForCryptohandler } from "../src/crypto_handler_importer"
import { disableElement, enableElement } from "../src/dom_helper"
import { findNested, findAllNested } from "../src/pdfmake_find_and_replace"
import { footer } from "../src/pdfmake_footer"
import { isEmptyRepeatedBlock } from "../src/note_decrypted_content_ui"

const PDF_STYLE_LABEL = "label"
const PDF_STYLE_SUBHEADING = "subheading"

const buildNoteDataStack = (decryptedContent, encryptedDataFormatVersion, stack, repeatedHeaderTranslation) => {
  Object.keys(decryptedContent).forEach(key => {
    const contentItem = decryptedContent[key]

    if (encryptedDataFormatVersion == 1) {
      stack.push([
        { text: key, style: PDF_STYLE_LABEL },
        { text: Array.isArray(contentItem) ? contentItem.join(", ") : contentItem }
      ])

    } else if (contentItem.fieldType !== FIELD_TYPES.repeatable) {
      // In v2, the submitted data is always an array, even if it only contains a single entry.
      stack.push([
        { text: contentItem.label, style: PDF_STYLE_LABEL },
        { text: contentItem.data.join("\n") }
      ])

    } else {
      contentItem.data.forEach((content, index) => {
        if (isEmptyRepeatedBlock(content)) { return }

        stack.push([
          { text: `${repeatedHeaderTranslation} ${index + 1}`, style: PDF_STYLE_SUBHEADING }
        ])

        buildNoteDataStack(content, encryptedDataFormatVersion, stack, repeatedHeaderTranslation)
      })
    }
  })

  return stack
}

export default class extends Controller {
  static targets = [
    "dropdownTrigger",
    "featureCheckbox"
  ]

  static values = {
    dataUrl: String,
    error: String,
    repeatedHeaderTranslation: String
  }

  // Events
  async onClick(event) {
    event.preventDefault()
    const container = event.target.closest(".card-body") || event.target

    try {
      disableElement(container)
      this.cryptoHandler = await waitForCryptohandler(this.application)
      const { pdfMake, data, files } = await this.fetchData()
      const pdf = await this.createPdf(pdfMake, data)
      this.saveAsZip(data, pdf, files)

      // Close the dropdown, which we have prevented at the beginning of this call.
      if (!this.hasDropdownTriggerTarget) { return }
      const dropdown = bootstrap.Dropdown.getInstance(this.dropdownTriggerTarget)
      dropdown?.hide()
    } catch (e) {
      console.error("Export note failed", e)
      window.alert(this.errorValue || "Error")
    } finally {
      enableElement(container)
    }
  }

  // Private
  async fetchData() {
    const params = new URLSearchParams()

    this.featureCheckboxTargets.forEach(element => {
      if (element.checked) { params.set(`features[${element.value}]`, "true") }
    })

    const url = `${this.dataUrlValue}?${params.toString()}`

    const [data, decryptedData, pdfMake] = await Promise.all([
      fetch(url).then(res => res.json()),
      this.getDecryptedContent(),
      this.getPdfMake(),
    ])

    const files = await Promise.all(data.files.map(file => this.fetchFile(file)))

    this.addDecryptedData(data, decryptedData)
    await this.decryptMessages(data)

    return {
      pdfMake,
      data,
      files
    }
  }

  async getPdfMake() {
    // pdfMake is not exposed as a module, it is registered as a global variable
    await import("pdfmake/build/pdfmake")
    return pdfMake
  }

  async getDecryptedContent() {
    const decryptionController = this.application.getControllerForElementAndIdentifier(
      document.querySelector(`[data-controller*="note-decryption"]`),
      "note-decryption"
    )

    return {
      encryptedDataFormatVersion: decryptionController.encryptedDataFormatVersion,
      decryptedJson: decryptionController.decryptedJson
    }
  }

  async fetchFile({ name, url, encrypted }) {
    return {
      name,
      content: await (encrypted
        ? this.cryptoHandler.decryptFileFromUrl(url)
        : fetch(url).then(res => res.blob()))
        .catch(e => { console.error("Failed to fetch file", e) })
    }
  }

  addDecryptedData(data, decryptedContent) {
    const el = findNested(data.pdfMake, el => el._iwhistle_encrypted_details_placeholder)
    // Depending on the selected features, the content might not exist. This used to be a hard error state, but no longer is.
    if (!el) { return }

    el.stack = buildNoteDataStack(
      decryptedContent.decryptedJson || {},
      decryptedContent.encryptedDataFormatVersion,
      [],
      this.repeatedHeaderTranslationValue
    )
  }

  async decryptMessages(data) {
    const encryptedMessages = findAllNested(data.pdfMake, el => el._iwhistle_encrypted_message)

    return Promise.all(encryptedMessages.map(async encryptedMessage => {
      try {
        encryptedMessage.text = await this.cryptoHandler.decryptText(encryptedMessage.text)
      } catch (e) {
        console.error("Failed to decrypt message", e)
      }
    }))
  }

  async createPdf(pdfMake, data) {
    data.pdfMake.footer = footer
    const pdf = pdfMake.createPdf(data.pdfMake, null, data.fonts)
    return new Promise(resolve => pdf.getBlob(resolve))
  }

  async saveAsZip(data, pdf, files) {
    const zip = new JSZip()
    zip.file(data.meta.filename_pdf, pdf)
    files.forEach(({ name, content }) => zip.file(name, content))
    const blob = await zip.generateAsync({type:"blob"})
    FileSaver.saveAs(blob, data.meta.filename_zip)
  }
}
