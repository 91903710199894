import { Controller } from "stimulus"
import QRCode from "qrcode"
import { SSID_PUBLIC_ENCRYPTION_PASSWORD } from "../config/storage_identifiers"

export default class extends Controller {
  static targets = ["passwordDisplay", "copied", "qrcode"]

  static values = {
    partnerName: String,
    loginUrl: String,
    noteUuid: String,
  }

  // Lifecycle
  connect() {
    const combinedPassword = this.generateCombinedPassword()
    this.generateQRCode(combinedPassword)
  }

  // Internal
  generateCombinedPassword() {
    if (!this.hasPasswordDisplayTarget) { return }

    const password = sessionStorage.getItem(SSID_PUBLIC_ENCRYPTION_PASSWORD)
    sessionStorage.removeItem(SSID_PUBLIC_ENCRYPTION_PASSWORD)
    if (!password) { return }

    const combinedPassword = `${this.noteUuidValue}#${password}`

    this.passwordDisplayTarget.innerText = combinedPassword
    return combinedPassword
  }

  generateQRCode(combinedPassword) {
    if (!combinedPassword) { return }

    const content = [
      "BEGIN:VCARD",
      "VERSION:2.1",
      `ORG:${this.partnerNameValue}`,
      `URL:${this.loginUrlValue}#${combinedPassword}`,
      `EMAIL;HOME;INTERNET:${combinedPassword}`,
      "END:VCARD"
    ].join("\n")

    QRCode.toCanvas(this.qrcodeTarget, content, { width: 150 })
  }
}
