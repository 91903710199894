import { Controller } from "stimulus"
import { hasInvalidFiles, hasTooManyFiles } from "../src/file_upload_encryption"

// This is just a validation/status/error handler, it has no actual functionality beyond that.
export default class extends Controller {
  static targets = ["errorMessage", "fileInput"]
  static values = {
    allowedFileExtensions: Array
  }

  // Entrypoints

  async checkSelectedFiles() {
    const anyInvalidFiles = await hasInvalidFiles(this.files, this.allowedFileExtensionsValue)

    if (anyInvalidFiles || hasTooManyFiles(this.files)) {
      this.showErrorMessage()
      this.clearFileInput()
    } else {
      this.hideErrorMessage()
    }
  }

  // Private

  clearFileInput() {
    if (!this.hasFileInputTarget) { return }
    this.fileInputTarget.value = null
  }

  hideErrorMessage() {
    if (!this.hasErrorMessageTarget) return
    this.errorMessageTarget.classList.add("alert-info")
    this.errorMessageTarget.classList.remove("alert-danger")
  }

  showErrorMessage() {
    if (!this.hasErrorMessageTarget) return
    this.errorMessageTarget.classList.add("alert-danger")
    this.errorMessageTarget.classList.remove("alert-info")
  }

  // Getters

  get files() {
    if (!this.hasFileInputTarget) { return [] }
    return Array.from(this.fileInputTarget.files)
  }
}
