import { Controller } from "stimulus"

export default class extends Controller {
    static values = { customLabel: String }
    static targets = ["label", "inputs"]

    // callbacks
    show() {
        this.labelTarget.innerHTML = this.customLabelValue
        this.inputsTarget.classList.toggle("d-none", false)
    }
}
