import { FIELD_TYPES } from "../config/forms"
import * as dom from "../src/dom_helper"

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/i

export const addNoteDataToContentUI = (decryptedContent, encryptedDataFormatVersion, parentElement) => {
  Object.keys(decryptedContent).forEach(key => {
    const contentItem = decryptedContent[key]

    if (encryptedDataFormatVersion == 1) {
      dom.addKeyValueDisplay(key, contentItem, parentElement)

    } else if (contentItem.fieldType !== FIELD_TYPES.repeatable) {
      // In v2, the submitted data is always an array, even if it only contains a single entry.
      dom.addKeyValueDisplay(contentItem.label, formatValue(contentItem.data.join("\n")), parentElement)

    } else {
      contentItem.data.forEach((content, index) => {
        if (isEmptyRepeatedBlock(content)) { return }

        dom.addKeyValueRepeatCountHeader(index, parentElement)
        addNoteDataToContentUI(formatValue(content), encryptedDataFormatVersion, parentElement)
      })
    }
  })
}

export const isEmptyRepeatedBlock = (decryptedContent) => {
  let isEmpty = true

  Object.keys(decryptedContent).forEach(key => {
    const content = decryptedContent[key]
    // Even single-answer text fields are encased in an array, and an array containing an empty string is itself not empty, so we just join everything and check that.
    if (content.data.join().length > 0) { isEmpty = false }
  })

  return isEmpty
}

const formatValue = (value) => {
  if (typeof value !== "string") { return value }
  if (!value.match(DATE_REGEX)) { return value }

  const date = new Date(value)
  const formattedDate = new Intl.DateTimeFormat(dom.getLocaleFromBody()).format(date)

  return formattedDate
}
