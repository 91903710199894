import { Controller } from "stimulus"
import { PDFDocument, StandardFonts, breakTextIntoLines } from "pdf-lib"
import {drawLogo, drawTextVerticallyCentered, createLinkOverlay, openPdfInNewTab} from "../src/pdf_lib"

async function createPdf(template, data) {
  const pdf = await PDFDocument.load(template)
  const font = await pdf.embedFont(StandardFonts.Courier)
  const pages = pdf.getPages()
  const [firstPage] = pages

  await addDataToPdf(pdf, firstPage, data, font)

  return pdf.save()
}

async function addDataToPdf(pdf, page, data, font) {
  await drawLogo(pdf, page, data.logoUrl, data.logoType)

  let urlFontSize = 11

  if (data.url.length > 75) {
    urlFontSize = 8
  } else if (data.url.length > 65) {
    urlFontSize = 9
  } else if (data.url.length > 60) {
    urlFontSize = 10
  }

  const urlRect = drawTextVerticallyCentered(page, data.url, {
    x: 90,
    y: 462,
    size: urlFontSize,
    maxWidth: 420,
    wordBreaks: ["/", "?", "&"],
    lineHeight: 8,
    font,
  })

  createLinkOverlay(page, data.url, urlRect)

  page.drawText(data.password, {
    x: 90,
    y: 403,
    size: 11,
    font,
  })
}

export default class extends Controller {
  static values = {
    noteUrl: String,
    templateUrl: String,
    filename: String,
    logoUrl: String,
    logoType: String,
  }
  static targets = ["passwordDisplay"]

  connect() { window.scrollTo(0, 0) }

  // Events
  async triggerPdfCreation() {
    const password = this.passwordDisplayTarget?.innerText
    if (!password) { return }

    const data = {
      password,
      url: this.noteUrlValue,
      logoUrl: this.logoUrlValue,
      logoType: this.logoTypeValue,
    }

    try {
      const template = await fetch(this.templateUrlValue).then(res => res.arrayBuffer())
      const pdfBytes = await createPdf(template, data)

      openPdfInNewTab(pdfBytes)
      window.dispatchEvent(new CustomEvent("public-note-saved"))
    } catch (e) {
      // TODO: Error handling.
      console.error("PDF creation failed", e)
    }
  }
}
